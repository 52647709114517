import { createAction, props } from '@ngrx/store';
import { sortByType, TimelineMode, TimelineSettings } from '../models';

export const setTimelineSettings = createAction(
  '[Timeline] Set Settings',
  props<{ timelineSettings: TimelineSettings }>()
);

export const setTimelineHeight = createAction(
  '[Timeline] Set Timeline Height',
  props<{ height: number }>()
);

export const setTimelineMode = createAction(
  '[Timeline] Set Timeline Mode',
  props<{ mode: TimelineMode }>()
);

export const setQuarterWidth = createAction(
  '[Timeline] Set Timeline Quarter Width',
  props<{ width: number }>()
);

export const setTimelineSort = createAction(
  '[Timeline] Set Timeline Sort',
  props<{ sort: sortByType }>()
);
