import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import {
  loadMoreReservations,
  loadReservations,
  loadReservationsSuccess,
  refreshReservations,
  setReservationsFilter,
} from '../actions';
import { betterLatestFrom } from '../utils';
import { allResources, reservationsSettings } from '../selectors';
import {  DataService, } from '../services';
import { AppState } from '../models';

@Injectable()
export class ReservationsEffects {
  refresh = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshReservations),
      betterLatestFrom(() => this.store.select(reservationsSettings)),
      switchMap((a) =>
        of(
          loadReservations({
            from: a[1].from,
            to: a[1].to,
            nextLink: null,
          })
        )
      )
    )
  );

  loadWhenFilterChanges = createEffect(() =>
    this.actions$.pipe(ofType(setReservationsFilter), map(loadReservations))
  );

  load = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReservations),
      betterLatestFrom((_) => this.store.select(reservationsSettings)),
      switchMap((action) =>
        forkJoin([
          this.store.select(allResources).pipe(
            filter((x) => !x.loading && x.data.length > 0),
            take(1)
          ),
          of(action),
        ])
      ),
      switchMap(([_resources, [action, _reservationSettings]]) =>
        this.dataService.getReservations(
          action.from,
          action.to,
          _resources.data,
          _reservationSettings
        )
      ),
      mergeMap(value => {
        const arr: any[] = [
          loadReservationsSuccess({
            events: value.events,
            nextLink: value.nextLink,
          }),
        ];

        // Treshold - alespon  5 eventu
        if (value.events.length < 5 && value.nextLink) {
          arr.push(loadMoreReservations());
        }
        return arr;
      })
    )
  );

  loadMore = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreReservations),
      betterLatestFrom((a) => this.store.select(reservationsSettings)),
      switchMap((value) =>
        of(
          loadReservations({
            from: value[1].from,
            to: value[1].to,
            nextLink: value[1].nextLink,
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private dataService: DataService,
  ) {}
}
