import {IdentifiedAssistEvent, Pack } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as ColleagueEventsActions from '../actions/colleague-events.actions';

export const initialState: Pack<IdentifiedAssistEvent[]> = {
  loading: false,
  data: [],
};

const reducer = createReducer(
  initialState,
  on(ColleagueEventsActions.loadColleagueEvents, (state) => ({
    ...state,
    loading: true,
  })),
  on(ColleagueEventsActions.colleagueEventsLoaded, (state, { events }) => ({
    ...state,
    loading: false,
    data: events.map((calendarEvent) => ({
      ...calendarEvent,
      orderItemsCount: 0,
    })),
  }))
);

export function colleagueeventsReducer(state: Pack<IdentifiedAssistEvent[]>, action: Action) {
  return reducer(state, action);
}
