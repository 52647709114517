import { inject, Inject, Injectable, Injector, Optional } from "@angular/core";

import { CALENDAR_SERVICE_LOADER, CALENDARS_SERVICE_REGISTRATION } from "../di/calendars-registration.model";
import { CalendarsService } from "./implementations";
import { CalendarServerType } from "../models/calendar-server-type.enum";
import { defer, Observable } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class CalendarsServiceFactoryService {

  implementations = inject(CALENDARS_SERVICE_REGISTRATION, {
    optional: true
  }) as unknown as CALENDAR_SERVICE_LOADER[];
  injector = inject(Injector);

  getImplementation(serverType: CalendarServerType): Observable<CalendarsService> {
    const loader = this.implementations.find(impl => impl.type === serverType);
    return defer(() => {
      return loader.load().then((impl) => {
        return this.injector.get(impl);
      });
    });
  }
}
