type getIndexType<TKey> = TKey extends string ? string : number;

export function groupBy<
  ItemType extends { [key: string]: any },
  TKey extends keyof ItemType
>(
  array: Array<ItemType>,
  key: TKey
): { [key in getIndexType<TKey>]: Array<ItemType> } {
  return array.reduce((acc, item) => {
    (acc[item[key]] = acc[item[key]] || []).push(item);
    return acc;
  }, {} as any);
}

export function onlyUnique<T = any>(value: T, index: number, self: T[]) {
    return self.indexOf(value) === index;
}

export function createOnlyUniqueBy<T = any>(distinctBy: (item: T) => any ) {
    return (value: T, index: number, self: T[]) => {
      const found = self.find(distinctBy);
      return self.indexOf(found) === index;
    };
}

export function distinctBy<T = any>(array: T[], distinctBy: (item: T) => any ) {
  const set = new Set(array.map(distinctBy));
  return [...set].map(key => array.find(a => distinctBy(a) === key));
}
