import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {ConfigService} from "../services";


@Injectable({ providedIn: 'root' })
export class ReservationsOrNavigationEnabled implements CanActivate {
  constructor(
    private router: Router,
    private  configService: ConfigService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      this.configService.value.enableReservations ||
      this.configService.value.navigationEnabled
    ) {
      return true;
    }
    return this.router.parseUrl('/dashboard');
  }
}
