import { PackedUser, Selections, SelectedSchedule } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as SelectionsActions from '../actions/selections.actions';

export const initialState: Selections = {
  selectedSchedules: [],
  hoveredSchedules: [],
};

const reducer = createReducer(
  initialState,
  on(
    SelectionsActions.addHoverEvent,
    (state, { eventId, scheduleId, requirementId, resourceId }) => {
      const foundSchedule = state.hoveredSchedules.find(
        (ss) => ss.scheduleId === scheduleId && ss.eventId === eventId
      );
      return {
        ...state,
        hoveredSchedules: foundSchedule
          ? state.hoveredSchedules
          : [
              ...state.hoveredSchedules,
              {
                eventId: eventId,
                scheduleId: scheduleId,
                requirementId: requirementId,
                resourceId: resourceId,
              },
            ],
      };
    }
  ),
  on(SelectionsActions.removeHoverEvent, (state, { eventId, scheduleId }) => {
    return {
      ...state,
      hoveredSchedules: state.hoveredSchedules.filter(
        (x) => x.eventId !== eventId && x.scheduleId !== scheduleId
      ),
    };
  }),
  on(SelectionsActions.selectResource, (state, { resourceId }) => {
    return {
      ...state,
      selectedResourceId: resourceId,
    };
  })
);

export function selectionsReducer(state: Selections, action: Action) {
  return reducer(state, action);
}
