import { createAction, props } from '@ngrx/store';
import { User } from '../models';

export const loadColleague = createAction(
  '[Colleague] Load',
  props<{ identifier: string }>()
);
export const unloadColleague = createAction('[Colleague] UnLoad');

export const colleagueLoaded = createAction(
  '[Colleague] Loaded',
  props<{ colleague: User }>()
);
