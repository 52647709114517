import {
  createSelectorFactory,
  defaultMemoize,
  DefaultProjectorFn,
  MemoizedSelector,
} from '@ngrx/store';
import { filter, interval, map, Observable, startWith, take } from "rxjs";

function innerObjectComparer<T extends {}>(a: T, b: T): boolean {
  if (a === b) return true;
  let same = true;
  Object.keys(a).forEach((key) => {
    if (a[key] !== b[key]) {
      same = false;
    }
  });
  return same;
}

export function objectSelector<SelectorOutput, Result, Origin>(
  memSelector: MemoizedSelector<
    Origin,
    any,
    DefaultProjectorFn<SelectorOutput>
  >,
  s1: (state: SelectorOutput) => Result
) {
  return createSelectorFactory<Origin, Result>((projectionFun) =>
    defaultMemoize(projectionFun, innerObjectComparer, innerObjectComparer)
  )(memSelector, s1);
}


export interface CountdownOptions {
  tickDuration?: number;
  emitZero?: boolean;
  emitInitial?: boolean;
}



export function countdown(ticks: number, options: CountdownOptions = {}): Observable<number> {
  const tickDuration = options.tickDuration ?? 1000;
  const emitZero = options.emitZero ?? true;
  const emitInitial = options.emitInitial ?? true;

  const countdown$ = interval(tickDuration).pipe(
    take(ticks),
    map(n => ticks - 1 - n),
    filter(value => emitZero || value !== 0)
  );

  if (emitInitial)
    return countdown$.pipe(startWith(ticks));
  else
    return countdown$;
}
