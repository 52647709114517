import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Portal, TemplatePortal } from "@angular/cdk/portal";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {


  _buttons$: BehaviorSubject<TemplatePortal<TemplateRef<unknown>>> = new BehaviorSubject<TemplatePortal<TemplateRef<unknown>>>(null);
  buttons$ = this._buttons$.asObservable();

   _title$: BehaviorSubject<string> = new BehaviorSubject('');
   title$= this._title$.asObservable();

  setTitle(title: string) {
    this._title$.next(title);
  }

  setButtons(portal: TemplatePortal<TemplateRef<unknown>>) {
    this._buttons$.next(portal);
  }

  removeButtons() {
    this._buttons$.next(null);
  }

}
