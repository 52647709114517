import { createSelector, MemoizedSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';
import { events } from './events.selectors';
import { allResources } from './resources.selectors';

const boundaries = [
  { percentage: 0, color: 0 },
  { percentage: 20, color: 20 },
  { percentage: 50, color: 50 },
  { percentage: 70, color: 100 },
  { percentage: 90, color: 170 },
  { percentage: 100, color: 255 },
];


export const schedules = createSelector(
  selectShared,
  (state) => state.schedules
);



export const floorplanSchedules = createSelector(
  selectShared,
  (state) => state.floorPlanSchedules
);
