export interface Operation {
  correlationId: string;
  type: string;
  loading: boolean;
  response: any;
  error?: any;
}

export function createOperation(
  type: string,
  loading: boolean,
  response: any,
  error: any,
  correlationId: string
): Operation {
  return {
    loading,
    response,
    type,
    correlationId,
    error,
  };
}

export function initOperation(type: string, correlationId: string) {
  return createOperation(type, false, null, null, correlationId);
}

export function busyOperation(type: string, correlationId: string) {
  return createOperation(type, true, null, null, correlationId);
}

export function successOperation(
  type: string,
  response: any,
  correlationId: string
) {
  return createOperation(type, false, response, null, correlationId);
}

export function errorOperation(
  type: string,
  error: any,
  correlationId: string
) {
  return createOperation(type, false, null, error, correlationId);
}
