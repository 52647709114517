import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../models';
import {AuthService, ConfigService} from "../services";

@Injectable({ providedIn: 'root' })
export class UserProhibitedGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthService,
    private configService: ConfigService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (location.pathname == '/account/guest') {
      if (this.configService.value.allowGuests) {
        return this.router.parseUrl('/dashboard');
      } else {
        return true;
      }
    }
    return true;
  }
}
