import { PackedAreas } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as AreasActions from '../actions/areas.actions';

export const initialState: PackedAreas = {
  loading: false,
  data: [],
};

const reducer = createReducer(
  initialState,
  on(AreasActions.loadAreas, (state) => ({
    ...state,
    loading: true,
  })),
  on(AreasActions.areasLoaded, (state, { areas }) => ({
    ...state,
    loading: false,
    data: areas,
  }))
);

export function areasReducer(state: PackedAreas, action: Action) {
  return reducer(state, action);
}
