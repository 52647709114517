import { Injectable } from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import { remove } from 'remove-accents';
import * as ResourcesActions from '../actions/resources.actions';
import * as RoomsActions from '../actions/resources.actions';
import * as FilterActions from '../actions/filter.actions';
import * as AreasActions from '../actions/areas.actions';
import * as ScheduleActions from '../actions/schedules.actions';
import {
  forkJoin,
  map,
  mergeMap,
  filter as rxjsFilter,
  switchMap,
  take,
} from 'rxjs';
import { betterLatestFrom, resourcesFullfilRequirement } from '../utils';
import { Store } from '@ngrx/store';
import { filter, floorplanFilter, getDefaultRequirements } from "../selectors/filter.selectors";

import {allAreas, allResources, currentUserSettings} from '../selectors';
import {addDays, differenceInDays,  startOfDay} from 'date-fns';
import { AssistResourceBM } from '@cue/api';
import { DataService } from '../services/data.service';
import {  ConfigService } from '../services';
import { AppState, FilterBase, FilteredResources, Requirement } from "../models";
import { LicensingService } from "@cue/licensing";

@Injectable()
export class SchedulesEffects {
  // FLOORPLANS

  floorplanPreviousDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.setFloorplanPreviousDayFilter),
      concatLatestFrom(()=> this.store.select(floorplanFilter)),
      map(([, currentFilter]) => FilterActions.setFloorplanFilter({
        filter: {
          ...currentFilter,
          date: addDays(currentFilter.date, -1),
          time: addDays(currentFilter.time, -1)
        }
      }))
    )
  );

  floorplanSetDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.setFloorplanDayFilter),
      concatLatestFrom(()=> this.store.select(floorplanFilter)),
      map(([action, currentFilter]) => FilterActions.setFloorplanFilter({
        filter: {
          ...currentFilter,
          date: action.newDate,
          time: addDays(currentFilter.time, differenceInDays(action.newDate, currentFilter.date))
        }
      }))
    )
  );


  floorplanNextDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.setFloorplanNextDayFilter),
      concatLatestFrom(()=> this.store.select(floorplanFilter)),
      map(([action, currentFilter]) => FilterActions.setFloorplanFilter({
        filter: {
          ...currentFilter,
          date: addDays(currentFilter.date, 1),
          time: addDays(currentFilter.time, 1)
        }
      }))
    )
  );






  refreshAfterFloorplanFilterSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FilterActions.setFloorplanFilter,
        FilterActions.setFloorplanFilterDateAndTime,
        FilterActions.setFloorplanFilterWithDuration,
        FilterActions.setFloorplanAvailability,
        FilterActions.setFloorplanFilterAreaId,
        FilterActions.setFloorplanFilterDateAndDuration
      ),
      map((_) => ScheduleActions.refreshFloorplanSchedules())
    )
  );

  refreshFloorplansWhenDataLoaded$ = createEffect(() =>
    forkJoin([
      this.actions$.pipe(ofType(RoomsActions.resourcesLoaded), take(1)),
      this.actions$.pipe(ofType(AreasActions.areasLoaded), take(1)),
    ]).pipe(
      mergeMap((_) => [
        FilterActions.setFloorplanFilterDefaults({
          duration: this.configService.value.timelineDefaults
            ? this.configService.value.timelineDefaults.defaultDuration
            : {
                hours: 2,
                minutes: 30,
              },
          start: {
                hours: 0,
                minutes: 0,
              },
          addEnd: {
            hours: 24,
            minutes: 0,
          },
        }),
        ScheduleActions.refreshFloorplanSchedules(),
      ])
    )
  );

  loadFloorplanSchedulesWhenFloorplanFilterHasChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.refreshFloorplanSchedules),
      switchMap((aciton) =>
        this.store
          .select(allAreas())
          .pipe(rxjsFilter((a) => !a.loading && a.data!.length > 0))
      ),
      betterLatestFrom((a) => this.store.select(floorplanFilter)),
      betterLatestFrom((a) => this.store.select(allResources)),
      betterLatestFrom((a) => this.store.select(allAreas())),
      betterLatestFrom((a) => this.store.select(currentUserSettings)),
      switchMap(([[[[_, _filter], resources], areas], settings]) => {

        const ids = areas.data.map(x=> x.id);
        const filteredIds = settings.data?.location.filteredAreaIds;
        const allowedIds = ids.filter(id => !filteredIds?.includes(id));

        const buckets: FilteredResources[] = [];
        const newAreaId =
          _filter.areaId != null
            ? _filter.areaId
            : areas.data!.filter((x) => x.parentAreaId == null)[0].id;
        if (_filter.requirements.length === 0) {
          const bucketsFromResourceTypes =
            this.configService.value.resourceTypeInfos.map((rtInfo, rIndex) => ({
              requirement: {
                areas: allowedIds,
                csCode: rtInfo.csCode,
                id: rIndex.toString(),
                filters: [],
                typeName: rtInfo.name,
                typeId: rtInfo.resourceTypeId,
                attendees: []
              },
              resources: resources.data!.filter(
                (resource) =>
                  resource.mapInfos.find(
                    (roomInfo) => newAreaId === roomInfo.areaId
                  ) != null && resource.resourceTypeId === rtInfo.resourceTypeId
              ),
              additional: false,
            }));

          buckets.push(...bucketsFromResourceTypes);
        } else {
          _filter.requirements.forEach((requirement) => {
            const resourcesToAdd = resources.data!.filter((resource) =>
              resourcesFullfilRequirement(resource, requirement, _filter, this.licensingService, this.configService)
            );
            buckets.push({
              requirement: requirement,
              resources: resourcesToAdd,
              additional: false,
            });
          });
        }

        return this.dataService.getSchedulesForResources(
          buckets,
          startOfDay(_filter.date),
          startOfDay(addDays(_filter.date,1))
        );
      }),
      map((x) => ([] as any).concat.apply([], x)),
      map((schedules) =>
        ScheduleActions.floorplanSchedulesLoaded({ schedules: schedules })
      )
    )
  );




  previousDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.setPreviousDayFilter),
      concatLatestFrom(()=> this.store.select(filter)),
      map(([action, currentFilter]) => FilterActions.setFilter({
        filter: {
          ...currentFilter,
          date: addDays(currentFilter.date, -1)
        }
      }))
    )
  );

  setDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.setDayFilter),
      concatLatestFrom(()=> this.store.select(filter)),
      map(([action, currentFilter]) => FilterActions.setFilter({
        filter: {
          ...currentFilter,
          date: action.newDate
        }
      }))
    )
  );


  nextDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.setNextDayFilter),
      concatLatestFrom(()=> this.store.select(filter)),
      map(([action, currentFilter]) => FilterActions.setFilter({
        filter: {
          ...currentFilter,
          date: addDays(currentFilter.date, 1)
        }
      }))
    )
  );


  refreshAfterFilterSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.setFilter),
      map((_) => ScheduleActions.refreshSchedules())
    )
  );

  refreshWhenDataLoaded$ = createEffect(() =>
    forkJoin([
      this.actions$.pipe(ofType(RoomsActions.resourcesLoaded), take(1)),
      this.actions$.pipe(ofType(ResourcesActions.resourcesLoaded), take(1)),
    ]).pipe(
      mergeMap((_) => [
        ScheduleActions.refreshSchedules(),
      ])
    )
  );



  loadSchedulesWhenFilterHasChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.refreshSchedules),
      betterLatestFrom((a) => this.store.select(filter)),
      betterLatestFrom((a) => this.store.select(allResources)),
      betterLatestFrom((a) => this.store.select(allAreas())),
      betterLatestFrom((a) => this.store.select(currentUserSettings)),
      betterLatestFrom((a) => this.store.select(getDefaultRequirements(this.configService.value.resourceTypeInfos))),
      switchMap(([[[[[_, _filter], resources], areas], settings], defaultReqs]) => {
        const buckets: FilteredResources[] = [];

        const ids = areas.data.map(x=> x.id);
        const filteredIds = settings.data?.location.filteredAreaIds;
        const allowedIds = ids.filter(id => !filteredIds?.includes(id));

        if (_filter.requirements.length === 0) {
          const bucketsFromResourceTypes =
            this.configService.value.resourceTypeInfos.map((rtInfo,rtInfoIndex) => ({
              requirement: {
                areas: allowedIds,
                id: rtInfoIndex.toString(),
                filters: [],
                typeName: rtInfo.name,
                csCode: rtInfo.csCode,
                typeId: rtInfo.resourceTypeId,
                attendees: []
              },
              resources: resources.data!.filter(
                (resource) =>     resourcesFullfilRequirement(resource, {
                  areas: allowedIds,
                  id: rtInfoIndex.toString(),
                  filters: [],
                  csCode: rtInfo.csCode,
                  typeName: rtInfo.name,
                  typeId: rtInfo.resourceTypeId
                }, _filter, this.licensingService, this.configService)
              ),
              additional: false,
              attendees: []
            }));

          buckets.push(...bucketsFromResourceTypes);
        } else {
          _filter.requirements.forEach((requirement) => {
            const resourcesToAdd = resources.data!.filter((resource) =>
              resourcesFullfilRequirement(resource, requirement, _filter, this.licensingService, this.configService)
            );
            buckets.push({
              requirement: requirement,
              resources: resourcesToAdd,
              additional: false,
            });
          });
        }

        const attendeeEmails = _filter.attendees.map(x=> x.value);


        if (_filter.name != null && _filter.name.length > 0) {
          const specialNameRequirement = {
            areas: allowedIds,
            id: 'name',
            filters: [],
            typeName: null,
            csCode: null,
            typeId: null,
            attendees: [],
          };

          buckets.push({
            requirement: specialNameRequirement,
            resources:resources.data.filter(x=>
              x.name.toLowerCase() == _filter.name.toLowerCase()
              || (x.displayNameForApp != null && x.displayNameForApp.toLowerCase() == _filter.name.toLowerCase())
              ),
            additional: false
          });
        }

        const scheduleRequest = this.dataService.getSchedulesForResources(
          buckets,
          startOfDay(_filter.date),
          startOfDay(addDays(_filter.date, 1))
        );

        const attendeeRequest = this.dataService.getScheduleForAttendees(
          attendeeEmails,
          startOfDay(_filter.date),
          startOfDay(addDays(_filter.date, 1))
        );

        const requests = [
          scheduleRequest,
          attendeeRequest
        ];

        return forkJoin(requests);
      }),
      map(([x, y]) => {
          return {
            schedules: ([] as any).concat.apply([], x),
            attendees: y.map(pair => {
              return {
                email: pair.email,
                availabilityView: pair.availabilityView,
                items: pair.items
              };
            })
          };
        }),
      map((data) =>
        ScheduleActions.schedulesLoaded({ schedules: data.schedules, attendees: data.attendees })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private configService: ConfigService,
    private dataService: DataService,
    private licensingService: LicensingService
  ) {}
}
