import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { from, map, Observable, of, Subject, switchMap, take, tap, withLatestFrom } from 'rxjs';
import {NgForage} from "ngforage";
import {AssistEvent} from "../models";



export interface AssistLog {
  error: {
    message: string;
    stack: string;
  };
  date: Date;
}

@Injectable()
export class AssistErrorHandler implements ErrorHandler {
  error$: Subject<any> = new Subject();
  events$: Subject<AssistEvent[]> = new Subject();
  constructor(
    private readonly ngf: NgForage,
    private zone: NgZone
  ) {


    this.events$.pipe(
      switchMap(events => this.getLoggedEvents().pipe(
        map(loggedEvents => [events, loggedEvents != null ? loggedEvents : []]))
      ),
      map(([newEvents, loggedEvents]) => {
        if (loggedEvents.length > AssistErrorHandler.MAX_ITEMS) {
          loggedEvents = loggedEvents.slice(loggedEvents.length -  AssistErrorHandler.MAX_ITEMS);
        }
        loggedEvents.push(...newEvents);
        return loggedEvents;
      }),
      switchMap(log => this.writeEventsLog(log))
    ).subscribe();

    this.error$.pipe(
      switchMap(error => this.getLog().pipe(
        map(log => [error, log != null ? log : []]))
      ),
      map(([error, log]) => {
        if (log.length > AssistErrorHandler.MAX_ITEMS) {
          log = log.slice(log.length -  AssistErrorHandler.MAX_ITEMS);
        }
        log.push(error);
        return log;
      }),
      switchMap(log => this.writeLog(log))
    ).subscribe();
  }

  static LOG_KEY = "ASSIST_LOG";
  static LOG_EVENTS_KEY = "ASSIST_LOG_EVENTS";
  static MAX_ITEMS = 100;


  handleError(error) {
    this.zone.runOutsideAngular(()=> {
      this.error$.next({
        date: new Date(),
        error: {
          message: error.message,
          stack: error.stack
        }
      } as AssistLog);

      console.error(error);
    });
  }

  handleEvents(events: AssistEvent[]) {
    this.events$.next(events);
    console.error(events);
  }



  getLog() {
     return from(this.ngf.getItem<AssistLog[]>(AssistErrorHandler.LOG_KEY));
  }

  writeLog(log: AssistLog[]) {
    return from(this.ngf.setItem(AssistErrorHandler.LOG_KEY, log));
  }

  getLoggedEvents() {
    return from(this.ngf.getItem<AssistEvent[]>(AssistErrorHandler.LOG_EVENTS_KEY));
  }

  writeEventsLog(events: AssistEvent[]) {
    return from(this.ngf.setItem(AssistErrorHandler.LOG_EVENTS_KEY, events));
  }
}
