import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
  AppState,
  Filter,
  FloorplanFilter,
  FloorplanRequirement,
  Requirement,
} from '../models';
import { selectShared } from './shared.selectors';
import { AssistResourceTypeInfoBM } from "@cue/api";



export const filter = createSelector(selectShared, (state) => state.filter);

export const floorplanFilter = createSelector(
  selectShared,
  (state) => state.floorplanFilter
);

export const floorplanFilterTime = createSelector(
  floorplanFilter,
  (state) => state.time
);

export const floorplanAreaIdFilter = createSelector(
  floorplanFilter,
  (state) => state.areaId
);

export const getRequirements = createSelector<AppState, Filter, Requirement[]>(
  filter,
  (f) => f.requirements
);


export const getDefaultRequirements = (resourceTypes: AssistResourceTypeInfoBM[]) =>  createSelector(
  selectShared,
  state => {
    const ids = state.areas.data.map(x=> x.id);
    const filteredIds = state.userSettings.data?.location.filteredAreaIds;
    const allowedIds = ids.filter(id => !filteredIds?.includes(id));
    return resourceTypes.forEach((rstInfo, index) => ({
        areas: allowedIds,
        id: index,
        equipments: [],
        typeName: rstInfo.name,
        typeId: rstInfo.resourceTypeId,
        attendees: []
      }));
  }
);

export const getRequirementById = createSelector(
  getRequirements,
  (reqs: Requirement[], props: { requirementId: string }) =>
    reqs.find((r) => r.id === props.requirementId)
);

export const getFloorplanRequirements = createSelector<
  AppState,
  FloorplanFilter,
  FloorplanRequirement[]
>(floorplanFilter, (f) => f.requirements);

export const getFloorplanRequirementById = createSelector(
  getFloorplanRequirements,
  (reqs: FloorplanRequirement[], props: { requirementId: string }) =>
    reqs.find((r) => r.id === props.requirementId)
);
