import { createAction, props } from '@ngrx/store';
import { Operation } from '../models';

export const setOperation = createAction(
  '[Operations] add',
  props<{ operation: Operation }>()
);
export const removeOperation = createAction(
  '[Operations] remove',
  props<{ operationType?: string; correlationId?: string }>()
);
