import { createSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';

export const currentUser = createSelector(
  selectShared,
  (state) => state.currentUser
);


export const currentUserSettings = createSelector(
  selectShared,
  (state) => state.userSettings
);
