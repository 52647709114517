import { Operation, OperationsState } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as OperationsActions from '../actions/operations.actions';

export const initialState: OperationsState = {
  byCorrelationId: {},
  byType: {},
};

const reducer = createReducer(
  initialState,
  on(
    OperationsActions.setOperation,
    (state, { operation, operation: { correlationId, type } }) => ({
      ...state,
      byCorrelationId: {
        ...state.byCorrelationId,
        [correlationId]: operation,
      },
      byType: {
        ...state.byType,
        [type]: state.byType[type]
          ? state.byType[type].map((op) =>
              op.correlationId === correlationId ? operation : op
            )
          : [operation],
      },
    })
  ),
  on(
    OperationsActions.removeOperation,
    (state, { operationType, correlationId }) => ({
      ...state,
      byCorrelationId: {
        ...state.byCorrelationId,
        [correlationId]: undefined,
      },
      byType: {
        ...state.byType,
        [operationType]: state.byType[operationType].filter(
          (op) => op.correlationId !== correlationId
        ),
      },
    })
  )
);

export function operationsReducer(state: OperationsState, action: Action) {
  return reducer(state, action);
}
