import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { map, tap} from 'rxjs';
import {AuthService } from '../services';


@Injectable({ providedIn: 'root' })
export class OAuthCallbackGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authCode = route.queryParams['code'];
    if (this.authService.getMode() === "touchone-calendar") {
      const redirectUrl =  localStorage.getItem('oauthRedirectUrl');
      const scope =  localStorage.getItem('scope');
      const connectionAgentId =  parseInt(localStorage.getItem('connectionAgentId'), 10);
      return this.authService.getCalendarTokenFromServer(authCode, redirectUrl, scope, connectionAgentId).pipe(
        tap((r) => {
          if (r && r.paired) {
            this.authService.saveApiToken(r.token);
            this.authService.saveApiRefreshToken(r.refreshToken);
          } else {
            // TODO - redirect na parovani
            this.authService.removeApiToken();
            this.authService.removeApiRefreshToken();
          }
        }),
        map((r) => {
          if (r.paired) {
            const redirectUrl = sessionStorage.getItem('redirect_url')!;
            sessionStorage.removeItem('redirect_url');
            return this.router.parseUrl(
              redirectUrl !== undefined ? redirectUrl : '/dashboard'
            );
          } else {
            return this.router.parseUrl('/account/pairing?pairinguid='+r.pairingUID,);
          }
        })
      );


    } else {
      return this.authService.getTokenFromServer(authCode).pipe(
        tap((r) => {
          if (r?.success) {
            this.authService.saveApiToken(r.data!.token);
            this.authService.saveApiRefreshToken(r.data!.refreshToken);
          } else {
            this.authService.removeApiToken();
            this.authService.removeApiRefreshToken();
          }
        }),
        map((r) => {
          if (r.success) {
            const redirectUrl = sessionStorage.getItem('redirect_url')!;
            sessionStorage.removeItem('redirect_url');
            return this.router.parseUrl(
              redirectUrl !== undefined ? redirectUrl : '/dashboard'
            );
          } else {
            return this.router.parseUrl('/account/login');
          }
        })
      );
    }


  }
}
