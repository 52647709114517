import {CalendarAttendee, CalendarDateTime, CalendarLocation, CalendarOrganizer} from "@cue/calendars";
import {SafeHtml} from "@angular/platform-browser";
import {AssistResourceBM} from "@cue/api";


export enum AssistEventType {
  Identified = "Identified",
  Anonymous = "Anonymous",
  Faulty = "Faulty"
}

export interface AnonymousAssistEvent  extends  AssistEvent{
  logged: Date;
  assistEventType: AssistEventType.Anonymous;
  reason: string;
  originalEvent: any;
}


export interface FaultyAssistEvent  extends  AssistEvent{
  logged: Date;
  assistEventType: AssistEventType.Faulty;
  originalEvent: any;
  error: any;
}


export interface IdentifiedAssistEvent  extends  AssistEvent{
  assistEventType: AssistEventType.Identified;

  // Zakladni eventove veci
  id: string;
  iCalUId: string;
  visibility: 'public' | 'private';
  attendees: CalendarAttendee[];
  end: CalendarDateTime;
  start: CalendarDateTime;
  organizer: CalendarOrganizer;
  status: { key: string; value: string };
  subject: string;
  locations: CalendarLocation[];
  bodyPreview: string;
  bodyHtml: string;
  seriesMasterId?: string;
  onlineMeetingProvider: string;
  type: 'singleInstance' | 'occurrence' | 'exception' | 'seriesMaster';

  // veci k assistovi navic
  isAllDay: boolean;
  accepted?: boolean;
  confirmed: boolean;
  canceled: boolean;
  isOrganizer: boolean;
  showConfirmButton: boolean;
  showChangeButton: boolean;
  showFinish: boolean;
  confirmUntil?: Date;
  afterEnd?: boolean;
  resource: AssistResourceBM;
  orderItemsCount: number;
  imageUrl: string;
  navigationEnabled: boolean;
  reservationsEnabled: boolean;
}

export interface AssistEvent {

  assistEventType: AssistEventType;

}


export function isIdentifiedAssistEvent(event: AssistEvent): event is IdentifiedAssistEvent {
  return event.assistEventType == AssistEventType.Identified;
}


export function isAnonymousAssistEvent(event: AssistEvent): event is AnonymousAssistEvent {
  return event.assistEventType == AssistEventType.Anonymous;
}


export function isFaultyAssistEvent(event: AssistEvent): event is FaultyAssistEvent {
  return event.assistEventType == AssistEventType.Faulty;
}
