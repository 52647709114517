import { Reservations } from '../models';
import { createReducer, Action, on } from '@ngrx/store';
import {
  loadReservations,
  loadReservationsSuccess,
  refreshReservations,
  setReservationsFilter,
} from '../actions';
import { EVENT_STATUSES } from '../utils/constants';
import { addDays, endOfWeek, startOfDay } from "date-fns";

export const initialState: Reservations = {
  loading: false,
  data: [],
  state: EVENT_STATUSES,
  from: startOfDay(new Date()),
  to: addDays(new Date(), 7),
  resourceNames: [],
  attendees: [],
};

const reducer = createReducer(
  initialState,
  on(loadReservations, (state, action) => {
    return { ...state, loading: true };
  }),
  on(refreshReservations, (state, action) => {
    return { ...state, data: [], nextLink: null };
  }),
  on(setReservationsFilter, (state, action) => {
    return {
      ...state,
      from: action.from,
      to: action.to,
      resourceNames: action.resourceNames,
      state: action.state,
      attendees: action.attendees,
      nextLink: null,
      data: [],
    };
  }),
  on(loadReservationsSuccess, (state, action) => {
    return {
      ...state,
      data: [...state.data, ...action.events],
      loading: false,
      nextLink: action.nextLink,
    };
  })
);

export function reservationsReducer(state: Reservations, action: Action) {
  return reducer(state, action);
}
