import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  EffectNotification,
  OnRunEffects,
} from '@ngrx/effects';
import * as RoomsActions from '../actions/resources.actions';

import { Observable, of, map, switchMap, filter, skipUntil } from 'rxjs';
import { DbService } from '../services/db.service';
import { addFavouriteById, ApiService, removeFavouriteById } from '@cue/api';

@Injectable()
export class RoomsEffects implements OnRunEffects {
  addFavourite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoomsActions.addToFavourite),
        switchMap((action) =>
          this.apiService.call(addFavouriteById(action.id))
        ),
        map((_) => RoomsActions.favouriteAdded())
      ),
    {
      dispatch: true,
    }
  );

  removeFavourite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoomsActions.removeFromFavourites),
        switchMap((action) =>
          this.apiService.call(removeFavouriteById(action.id))
        ),
        map((_) => RoomsActions.favouriteRemoved())
      ),
    {
      dispatch: true,
    }
  );

  // Remove after server
  loadResourcesAfterInitialized$ = createEffect(() =>
    this.dbService.initialized$.pipe(
      filter((x) => x),
      map((_) => RoomsActions.loadResources())
    )
  );

  loadResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomsActions.loadResources),
      switchMap((_) => of(this.dbService.resources)),
      map((rooms) => RoomsActions.resourcesLoaded({ resources: rooms }))
    )
  );

  onlyLoadResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoomsActions.onlyLoadResources),
      switchMap((_) => of(this.dbService.resources)),
      map((rooms) => RoomsActions.onlyResourcesLoaded({ resources: rooms }))
    )
  );

  constructor(
    private actions$: Actions,
    private dbService: DbService,
    private apiService: ApiService
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return resolvedEffects$.pipe(skipUntil(this.dbService.initialized$));
  }
}
