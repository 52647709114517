import {
  NgModule,
  Optional,
  Inject,
  ModuleWithProviders,
  EnvironmentProviders,
  makeEnvironmentProviders, ENVIRONMENT_INITIALIZER, inject, importProvidersFrom
} from "@angular/core";
import {
  TRANSLOCO_CONFIG,
  TranslocoConfig,
  TranslocoModule,
  TranslocoService
} from "@ngneat/transloco";
import { LOCALIZATIONS } from "./di";
import { Localization } from "./models";


function mergeArraysToArray<T = any>(arrays: Array<Array<T>>): Array<T> {
  return [].concat.apply([], arrays as any);
}


const initializer = {
  provide: ENVIRONMENT_INITIALIZER,
  multi: true,
  useValue() {
    const service = inject(TranslocoService);
    const localizations = inject(LOCALIZATIONS, { optional: true }) as unknown as Localization[][];
    setTranslations(service, localizations);
  }
};


function setTranslations(translocoService: TranslocoService, localizations: Localization[][]) {
  const finalArr = mergeArraysToArray(localizations);
  finalArr.forEach((localization) => {
    translocoService.setTranslation({}, localization.language, {
      merge: false
    });
  });
}


export function provideLocalization(options: {
  localizations: Localization[];
  translocoConfig: TranslocoConfig;
}) {
  return makeEnvironmentProviders([
    importProvidersFrom(TranslocoModule),
    {
      provide: TRANSLOCO_CONFIG,
      useValue: options.translocoConfig
    },
    {
      provide: LOCALIZATIONS,
      useValue: options.localizations,
      multi: true
    },
    initializer
  ]);
}

export function provideFeatureLocalization(localizations: Localization[]) {
  return [
    {
      provide: LOCALIZATIONS,
      useValue: localizations,
      multi: true
    },
    initializer
  ];
}

