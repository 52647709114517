import { ErrorHandler, Injectable }        from '@angular/core';
import { AssistAreasBM, AssistResourceBM } from '@cue/api';
import { map, Observable }                 from 'rxjs';
import { Store }                from '@ngrx/store';
import { AppState, Area, Pack, ResourceDetail } from "../models/index";
import { allAreas }             from '../selectors/index';
import { ConfigService }                   from './config.service';


@Injectable({
              providedIn: 'root',
            })
export class ResourceService {

  constructor(
    private store: Store<AppState>,
    private configService: ConfigService,
  ) {
  }

  getBreadcrumbForResource(resource: AssistResourceBM | ResourceDetail): Observable<Pack<any[]>> {
    return this.store.select(
                 allAreas(),
               )
               .pipe(map(value => {

                 if (value.loading) {
                   return {
                     loading: true,
                     data: [],
                   };
                 }

                 let breadcrumb: any[] = [];

                 let pointer = value.data.find(x => x.id == resource.mapInfos[0].areaId);

                 do {
                   breadcrumb = [
                     {
                       area: pointer,
                       name: pointer.name,
                     },
                     ...breadcrumb,
                   ];
                   pointer = value.data.find(x => x.id == pointer.parentAreaId);
                 } while (pointer?.parentAreaId != null);

                 return {
                   loading: false,
                   data: breadcrumb,
                 };
               }));
  }

  getBreadcrumbForResourceInstant(resource: AssistResourceBM,
                                  areas: Area[],
  ): any[] {


    let breadcrumb: any[] = [];

    let pointer = areas.find(x => x.id == resource.mapInfos[0].areaId);

    do {
      breadcrumb = [
        {
          area: pointer,
          name: pointer.name,
        },
        ...breadcrumb,
      ];
      pointer = areas.find(x => x.id == pointer.parentAreaId);
    } while (pointer?.parentAreaId != null);
    return breadcrumb;
  }

  getNewMapUrlForResource(resource: AssistResourceBM | ResourceDetail): Observable<Pack<string>> {
    return this.store.select(
                 allAreas(),
               )
               .pipe(map(value => {

                 if (value.loading) {
                   return {
                     loading: true,
                     data: '',
                   };
                 }

                 const pointer = value.data.find(x => x.id == resource.mapInfos[0].areaId);
                 return {
                   loading: false,
                   data: pointer.newFloorplan,
                 };

               }));

  }


}
