import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../models';

import { filter, mapTo, Observable, take, tap } from 'rxjs';
import { allResources } from '../selectors';
import { DbService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ResourcesLoadedGuard implements CanActivate {
  constructor(private store: Store<AppState>, private dbService: DbService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.dbService.initialize().subscribe();
    return this.store.select(allResources).pipe(
      filter((x) => !x.loading && x.data!.length > 0),
      take(1),
      tap((_) => {
        var el = document.getElementById('appLoading');
        if (el) {
          el.parentElement!.removeChild(el);
        }
      }),
      mapTo(true)
    );
  }
}
