import { getHours, getMinutes, set } from 'date-fns';
import { CalendarAvailability } from '@cue/calendars';
import { TimelineEvent, TimelineRoomGroup } from '../models';

const combineAvailabityBits = (
  bitA: CalendarAvailability,
  bitB: CalendarAvailability
) => {
  return bitA === CalendarAvailability.free &&
    bitB === CalendarAvailability.free
    ? CalendarAvailability.free
    : bitA > bitB
    ? bitA
    : bitB;
};

export const calculateCompatibleEvents = (roomgroups: TimelineRoomGroup[]) => {
  roomgroups.forEach((rg) => {
    rg.timelineSchedules.forEach((ts) => {
      const otherEventsGroups = roomgroups
        .filter((x) => x.requirement.id !== rg.requirement.id)
        .reduce((acc, group) => {
          acc.push({
            requirementId: group.requirement.id,
            timelineEvents: [],
          });
          return acc;
        }, []);
      ts.events = ts.events.map((e) =>
        calculateCompatibleEventsForEvents(e, otherEventsGroups)
      );
    });
  });

  return roomgroups;
};

export const getNextQuarter = (dateTime: Date) => {
  const quarters = [
    {
      curr: 15,
      futureMinutes: 15,
      addHours: 0,
    },
    {
      curr: 30,
      futureMinutes: 30,
      addHours: 0,
    },
    {
      curr: 45,
      futureMinutes: 0,
      addHours: 1,
    },
    {
      curr: 60,
      futureMinutes: 0,
      addHours: 1,
    },
  ];
  const minutes = getMinutes(dateTime);
  const filteredQuarters = quarters.filter((x) => x.curr >= minutes);
  const nextSettings = filteredQuarters[0];
  return set(dateTime, {
    minutes: nextSettings.futureMinutes,
    hours: getHours(dateTime) + nextSettings.addHours,
  });
};

export const calculateCompatibleEventsForEvents = (
  event: TimelineEvent,
  otherGroupEvents: { requirementId: string; timelineEvents: TimelineEvent[] }[]
) => {
  return event;
};
