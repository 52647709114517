import { TranslocoPipe } from "@ngneat/transloco";
import { AssistResourceBM } from "@cue/api";
import {
  CalendarAttendee,
  CalendarAvailability,
  CalendarEvent,
  CalendarLocation,
  CalendarSchedulingSettings,
  CalendarStatus
} from "@cue/calendars";
import { ConfigService } from "../services";
import {
  Filter,
  FilterAttendee,
  FilterBase,
  IdentifiedAssistEvent,
  Requirement,
  Schedule,
  WorkingHours
} from "../models";
import { add, addDays, addMinutes, differenceInMinutes, endOfDay, isWithinInterval, set, startOfDay } from "date-fns";
import { getTimeZoneFromOfficeString } from "./timezone.function";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { scheduleHelper } from "./schedule-helper";
import { LicensingService } from "@cue/licensing";
import { remove } from "remove-accents";

export function getIndexFromDate(start: Date, date: Date): number {
  const index = Math.ceil(differenceInMinutes(date, start, { roundingMethod: "ceil" }) / 15);
  return index;
}

export function isDifferent(previousValue: any, nextValue: any) {
  const previousFreeOrFreeWorkingHours = previousValue === CalendarAvailability.free || previousValue === CalendarAvailability.freeOutsideWorkingHours;
  const nextFreeOrFreeWorkingHours = nextValue === CalendarAvailability.free || nextValue === CalendarAvailability.freeOutsideWorkingHours;
  if (previousFreeOrFreeWorkingHours && nextFreeOrFreeWorkingHours) {
    return false;
  }
  return previousValue !== nextValue;
};


export function getDateFromIndex(start: Date, index: number): Date {
  const date = addMinutes(start, index * 15);
  return date;
}


export function patchAvaibalityFromTePast(now: Date,
                                          start: Date,
                                          availabilityView: string | CalendarAvailability[]
) {
  const data: CalendarAvailability[] = Array.isArray(availabilityView) ? availabilityView
    : availabilityView.split("") as CalendarAvailability[];
  return data.map((value, index) =>
    (getDateFromIndex(start, index + 1) < now) ? CalendarAvailability.limited
      : value);
}

export function patchAvailabilityByRestrictions(
  resourceTimezone: string,
  startDate: Date,
  now: Date,
  availabilityView: string | CalendarAvailability[],
  schedulingSettings?: CalendarSchedulingSettings,
  restrictedTo?: "you" | "other"
): CalendarAvailability[] {


  const data: CalendarAvailability[] = Array.isArray(availabilityView) ? availabilityView
    : availabilityView.split("") as CalendarAvailability[];


  if (restrictedTo == "you") {
    return data.map((bit) =>
      bit == CalendarAvailability.free ? CalendarAvailability.free : bit
    );
  }

  if (restrictedTo == "other") {
    return data.map((bit) =>
      bit == CalendarAvailability.free ? CalendarAvailability.free : bit
    );
  }

  if (schedulingSettings?.maxAdvanceDays != null) {


    const timezone = getTimeZoneFromOfficeString(resourceTimezone);
    const timeInZone = utcToZonedTime(addDays(now, schedulingSettings.maxAdvanceDays), timezone);
    const endDate = endOfDay(timeInZone);


    const maxIndex = getIndexFromDate(startDate, endDate) - 1;
    return data.map((bit, index) => index > maxIndex ? CalendarAvailability.limited : bit);
  }

  return data;
}


export function mergeAttendeesAvailability(attendeesAvailabilities: FilterAttendee[],
                                           start: Date,
                                           end: Date
): CalendarAvailability[] {
  const availabilities = attendeesAvailabilities.map(x => x.availabilityView.split(""));
  const final = generateAvailability(start, end, CalendarAvailability.free);
  for (let i = 0; i < final.length; i++) {
    for (let j = 0; j < attendeesAvailabilities.length; j++) {
      if (availabilities[j][i] !== CalendarAvailability.free && availabilities[j][i] !== CalendarAvailability.tentative) {
        final[i] = CalendarAvailability.reserved;
        break;
      }
    }
  }
  return final;
}

export function daysBetweemDates(fromDate: Date, toDate: Date): Date[] {
  const days: Date[] = [];

  let firstDay = fromDate;
  while (true) {
    // Add day with hours
    days.push(firstDay);

    firstDay = add(firstDay, { days: 1 });
    if (firstDay > toDate) break;
  }
  return days;
}



export function patchSchedules(
  schedules: Schedule[],
  filter: Filter,
  resources: AssistResourceBM[],
  loadedAttendees: FilterAttendee[]
): Schedule[] {


  return schedules.map((schedule) => {
const attendees = filter.attendees;
    const requirementAttendeeEmails = attendees.filter(x => !x.optional).map(x => (x.value ?? '').toUpperCase());
    const requiredAttendees = loadedAttendees.filter(la => requirementAttendeeEmails.includes(la.email.toUpperCase()));
    const resourceTimeZone = resources.find((x) => x.id == schedule.resourceId)!.timezone;
    const reservedTo = resources.find((x) => x.id == schedule.resourceId)!
      .conditions.reservedTo;

    let computedCalendarAvailability = schedule.availabilityView.split("") as CalendarAvailability[];
    computedCalendarAvailability = scheduleHelper.patchAvailabilityByRestrictions(
      resourceTimeZone,
      startOfDay(filter.date),
      computedCalendarAvailability,
      schedule.schedulingSettings,
      reservedTo
    );

    computedCalendarAvailability =
      scheduleHelper.patchAvailabilityViewByWorkingHoursAndLimits(
        computedCalendarAvailability,
        schedule.workingHours,
        resourceTimeZone,
        filter,
        schedule.schedulingSettings
      );

    computedCalendarAvailability = scheduleHelper.patchAvailabilityViewByLimits(
      computedCalendarAvailability,
      filter
    );


    const patchedByAttendees = patchAvailabilityByAttendees(startOfDay(filter.date), endOfDay(filter.date), computedCalendarAvailability, requiredAttendees);
    return {
      ...schedule,
      availabilityView: patchedByAttendees.join("")
    } as Schedule;
  });
};


export function patchAvailabilityByAttendees(start: Date,
                                             end: Date,
                                             availabilityView: string | CalendarAvailability[],
                                             attendeesAvailabilities: FilterAttendee[]
) {
  const data: CalendarAvailability[] = Array.isArray(availabilityView) ? availabilityView
    : availabilityView.split("") as CalendarAvailability[];
  const attendesAvailabiliies: CalendarAvailability[] = mergeAttendeesAvailability(attendeesAvailabilities, start, end);

  const final = generateAvailability(start, end, CalendarAvailability.free);
  for (let i = 0; i < final.length; i++) {
    final[i] = data[i];
    if (attendesAvailabiliies[i] !== CalendarAvailability.free) {
      final[i] = CalendarAvailability.limited;
    }
  }

  return final;
}


export function patchAvailabilityByWorkingHours(
  start: Date,
  availabilityView: string | CalendarAvailability[],
  wHours: WorkingHours,
  resourceTimeZoneName: string,
  schedulingSettings?: CalendarSchedulingSettings
) {
  const data: CalendarAvailability[] = Array.isArray(availabilityView) ? availabilityView
    : availabilityView.split("") as CalendarAvailability[];
  const workingHoursAvailable = wHours != null && (wHours.daysOfWeek !== null);
  if (!workingHoursAvailable) {
    return data;
  }


  const currentTimeZone = Intl.DateTimeFormat()
    .resolvedOptions().timeZone;

  return data.map((value, index) => {


    if (value !== CalendarAvailability.free) {
      return value;
    }
    // Časový rozsah daného "slotu" na daném indexu
    const originalIndexStartDate = getDateFromIndex(start, index);
    const originalIndexEndDate = add(originalIndexStartDate, { minutes: 14 });

    const resourceTimeZone = getTimeZoneFromOfficeString(resourceTimeZoneName);

    // Den v timezone resource
    const startTimeDay = formatInTimeZone(originalIndexStartDate, resourceTimeZone, "iiii");
    const startTimeInWorkDays = wHours.daysOfWeek.some(
      (dow) => dow.toUpperCase() === startTimeDay.toUpperCase()
    );

    // Den v timezone resourcu
    const endTimeDay = formatInTimeZone(originalIndexEndDate, resourceTimeZone, "iiii");
    const endTimeInWorkDays = wHours.daysOfWeek.some(
      (dow) => dow.toUpperCase() === endTimeDay.toUpperCase()
    );

    if (wHours.startTime == null || wHours.endTime == null) {
      return startTimeInWorkDays && endTimeInWorkDays ? value
        : schedulingSettings?.rejectOutsideWorkingHours
          ? CalendarAvailability.closed
          : CalendarAvailability.freeOutsideWorkingHours;
    }


    const startTimeHours = parseInt(wHours.startTime.split(":")[0], 10);
    const startTimeMinutes = parseInt(wHours.startTime.split(":")[1], 10);
    const endTimeHours = parseInt(wHours.endTime.split(":")[0], 10);
    const endTimeMinutes = parseInt(wHours.endTime.split(":")[1], 10);

    const indexStartDateInZone = utcToZonedTime(originalIndexStartDate, resourceTimeZone);
    const indexEndDateInZone = utcToZonedTime(originalIndexEndDate, resourceTimeZone);

    const modifiedStart = set(indexStartDateInZone, {
      hours: startTimeHours,
      minutes: startTimeMinutes
    });

    const modifiedStart2 = set(indexStartDateInZone, {
      hours: endTimeHours,
      minutes: endTimeMinutes
    });

    const modifiedEnd = set(indexEndDateInZone, {
      hours: startTimeHours,
      minutes: startTimeMinutes
    });

    const modifiedEnd2 = set(indexEndDateInZone, {
      hours: endTimeHours,
      minutes: endTimeMinutes
    });

    const startTimeBetween = isWithinInterval(indexStartDateInZone, {
      start: modifiedStart,
      end: modifiedStart2
    });

    const endTimeBetween = isWithinInterval(indexEndDateInZone, {
      start: modifiedEnd,
      end: modifiedEnd2
    });
    return startTimeInWorkDays &&
    endTimeInWorkDays &&
    startTimeBetween &&
    endTimeBetween
      ? value
      :  schedulingSettings?.rejectOutsideWorkingHours
        ? CalendarAvailability.closed
        : CalendarAvailability.freeOutsideWorkingHours;

  });
}


export function extractMaxAvailabilityWithin(availabilityView: CalendarAvailability[],
                                             start: Date,
                                             origin: Date
): { leftIndex: number, rightIndex: number, leftDate: Date, rightDate: Date } {
  const originIndex = getIndexFromDate(start, origin);
  let leftIndex = originIndex;
  let rightIndex = originIndex;


  while ((availabilityView[leftIndex - 1] === CalendarAvailability.free) || (availabilityView[leftIndex - 1] === CalendarAvailability.freeOutsideWorkingHours)) {
    leftIndex--;
  }

  while ((availabilityView[rightIndex + 1] === CalendarAvailability.free) || (availabilityView[rightIndex + 1] === CalendarAvailability.freeOutsideWorkingHours)) {
    rightIndex++;
  }
  rightIndex = rightIndex + 1;

  const leftDate = getDateFromIndex(start, leftIndex);
  const rightDate = getDateFromIndex(start, rightIndex);


  return {
    leftDate,
    leftIndex,
    rightIndex,
    rightDate
  };
}

export function getPatchedAvailabilityWithEvent(availabilityView: CalendarAvailability[],
                                                start: Date,
                                                eventStart: Date,
                                                eventEnd: Date
) {
  return availabilityView.map((value, index) => {
    const currentDate = getDateFromIndex(start, index + 1);
    if (isWithinInterval(currentDate, {
      start: eventStart,
      end: eventEnd
    })) {
      return CalendarAvailability.free;
    } else {
      return value;
    }
  });
}

export function generateAvailability(from: Date, to: Date, availability: CalendarAvailability) {
  const fixedFrom = from < to ? from : to;
  const fixedTo = to > from ? to : from;
  const index = Math.ceil(differenceInMinutes(fixedTo, fixedFrom, { roundingMethod: "ceil" }) / 15);
  return new Array(index).fill(availability);
}

export function getAvailabilityFromView(availabilityView: CalendarAvailability[],
                                        from: Date,
                                        target: Date
) {
  const index = differenceInMinutes(target, from, { roundingMethod: "ceil" }) / 15;
  return availabilityView[index];
}

export function getSubAvailabilityFromAvailability(availabiltyviView: CalendarAvailability[],
                                                   originalFrom: Date,
                                                   from: Date,
                                                   to: Date
) {
  const startIndex = Math.floor(differenceInMinutes(from, originalFrom, { roundingMethod: "ceil" }) / 15);
  const endIndex = Math.floor(differenceInMinutes(to, originalFrom, { roundingMethod: "ceil" }) / 15);
  return availabiltyviView.slice(startIndex, endIndex);

}

export function getTotallAvailability(availabilityView: CalendarAvailability[]) {
  if (availabilityView.find(x => x == CalendarAvailability.closed)) {
    return CalendarAvailability.closed;
  }
  if (availabilityView.find(x => x == CalendarAvailability.reserved)) {
    return CalendarAvailability.reserved;
  }
  if (availabilityView.find(x => x == CalendarAvailability.limited)) {
    return CalendarAvailability.limited;
  }
  if (availabilityView.find(x => x == CalendarAvailability.assignedToOthers)) {
    return CalendarAvailability.assignedToOthers;
  }
  if (availabilityView.find(x => x == CalendarAvailability.tentative)) {
    return CalendarAvailability.tentative;
  }
  return CalendarAvailability.free;
}

export function prepareEventForDetail(
  resource: AssistResourceBM,
  translation: any,
  translocoPipe: TranslocoPipe,
  configService: ConfigService,
  status: { key: string; value: string } | null,
  start?: Date,
  end?: Date,
  requirementId?: string
) {
  let subject = translocoPipe.transform(
    translation.resourceDetail.defaultTitle
  );

  let description =
    translocoPipe.transform(translation.resourceDetail.pleaseJoinMe);

  if (configService.value.reservationDefaults.subjectText) {
    subject = configService.value.reservationDefaults.subjectText as any;
  }

  if (configService.value.reservationDefaults.descriptionText) {
    description = configService.value.reservationDefaults
      .descriptionText as any;
  }

  const resourceTypeId = resource.resourceTypeId;
  const defaultTitle = configService.value.resourceTypeInfos.find(x => x.resourceTypeId == resourceTypeId)?.customTitle;
  const defaultDescription = configService.value.resourceTypeInfos.find(x => x.resourceTypeId == resourceTypeId)?.customDescription;
  if (defaultTitle) {
    subject = defaultTitle;
  }

  if (defaultDescription) {
    description = defaultDescription;
  }
  return {
    start: start ? {
      timeZone: "UTC",
      dateTime: start.toISOString()
    } : null,
    end: end ? {
      timeZone: "UTC",
      dateTime: end.toISOString()
    } : null,
    subject: subject,
    status: status,
    bodyPreview: description,
    bodyHtml: description,
    requirementId: requirementId,
    visibility: "public"
  };
}

export function findResourceFromEvent(
  event: CalendarEvent,
  resources: AssistResourceBM[]
) {
  const resourcesInAttendees = event.attendees
    ? event.attendees.filter((x) => x.resource === true)
    : [];
  if (resourcesInAttendees.length === 1) {
    return resources.find(
      (x) =>
        x.username?.toUpperCase() ===
        resourcesInAttendees[0].emailAddress.address?.toUpperCase() ||
        x.username?.toUpperCase() ===
        resourcesInAttendees[0].emailAddress.name?.toUpperCase() ||
        x.username.toUpperCase() ===
        resourcesInAttendees[0].emailAddress.address?.toUpperCase() ||
        x.name?.toUpperCase() ===
        resourcesInAttendees[0].emailAddress.address?.toUpperCase() ||
        x.name?.toUpperCase() ===
        resourcesInAttendees[0].emailAddress.name?.toUpperCase() ||
        x.email.toUpperCase() ===
        resourcesInAttendees[0].emailAddress.address?.toUpperCase()
    );
  }

  const foundFromLocation = resources.find(
    (x) =>
      event.locations.find(location =>
        x.username?.toUpperCase() === location.uniqueId?.toUpperCase() ||
        x.username?.toUpperCase() === location.displayName?.toUpperCase() ||
        x.username.toUpperCase() === location.uniqueId?.toUpperCase() ||
        x.name?.toUpperCase() === location.uniqueId?.toUpperCase() ||
        x.name?.toUpperCase() === location.displayName?.toUpperCase() ||
        x.email.toUpperCase() === location.uniqueId?.toUpperCase()) != null
  );

  if (foundFromLocation) {
    return foundFromLocation;
  } else {
    return resources.find(resource => {
      return event.attendees.find(attendee =>
        attendee.emailAddress?.address?.toUpperCase() === resource.email?.toUpperCase() ||
        attendee.emailAddress?.address?.toUpperCase() === resource.username?.toUpperCase() ||
        attendee.emailAddress?.address?.toUpperCase() === resource.displayName?.toUpperCase() ||
        attendee.emailAddress?.name?.toUpperCase() === resource.email?.toUpperCase() ||
        attendee.emailAddress?.name?.toUpperCase() === resource.username?.toUpperCase() ||
        attendee.emailAddress?.name?.toUpperCase() === resource.displayName?.toUpperCase()
      ) != null;
    });
  }
}

export function findResourceFromUniqueIdAndDisplayName(
  uniqueId: string,
  displayName: string,
  resources: AssistResourceBM[]
): AssistResourceBM {
  return resources.find(
    (x) =>
      x.username?.toUpperCase() === uniqueId?.toUpperCase() ||
      x.username?.toUpperCase() === displayName?.toUpperCase() ||
      x.name?.toUpperCase() === uniqueId?.toUpperCase() ||
      x.name?.toUpperCase() === displayName?.toUpperCase() ||
      x.email.toUpperCase() === displayName?.toUpperCase() ||
      x.email.toUpperCase() === uniqueId?.toUpperCase()
  )!;
}

export function getImageUrlFromResource(
  resource: AssistResourceBM
) {
  return resource && resource.files.length > 0
    ? resource.files[0].file
    : "";
}


export function tryToFindLocation(
  item: any,
  resources: AssistResourceBM[]
): CalendarLocation {
  try {
    if (resources.length === 0) {
      return null;
    }

    if (item.location != null) {
      const splits = item.location.trim()
        .split(",");
      const mappedFromSplits = splits
        .map((x: any) => findResourceFromUniqueIdAndDisplayName(x, x, resources))
        .filter((x: any) => x != null);
      if (mappedFromSplits.length > 0) {
        return {
          uniqueId: mappedFromSplits[0].email,
          displayName: mappedFromSplits[0].name,
          locationType: "N/A",
          locationUri: "N/A",
          uniqueIdType: "N/A"
        };
      }
    } else {
      const resourcesInAttendees = item.attendees
        ? item.attendees.filter((x: CalendarAttendee) => x.resource === true)
        : [];
      if (resourcesInAttendees.length === 1) {
        const foundFromAttendees = resources.find(
          (x) =>
            x.username?.toUpperCase() ===
            resourcesInAttendees[0].email?.toUpperCase() ||
            x.username?.toUpperCase() ===
            resourcesInAttendees[0].displayName?.toUpperCase() ||
            x.username?.toUpperCase() ===
            resourcesInAttendees[0].emailAddress?.address?.toUpperCase() ||
            x.username?.toUpperCase() ===
            resourcesInAttendees[0].emailAddress?.name?.toUpperCase() ||
            x.name?.toUpperCase() ===
            resourcesInAttendees[0].email?.toUpperCase() ||
            x.name?.toUpperCase() ===
            resourcesInAttendees[0].displayName?.toUpperCase() ||
            x.name?.toUpperCase() ===
            resourcesInAttendees[0].emailAddress?.address?.toUpperCase() ||
            x.name?.toUpperCase() ===
            resourcesInAttendees[0].emailAddress?.name?.toUpperCase() ||
            x.email?.toUpperCase() ===
            resourcesInAttendees[0].email?.toUpperCase() ||
            x.email.toUpperCase() ===
            resourcesInAttendees[0].emailAddress?.address?.toUpperCase()
        );
        return {
          uniqueId: foundFromAttendees!.email,
          displayName: foundFromAttendees!.name,
          locationType: "N/A",
          locationUri: "N/A",
          uniqueIdType: "N/A"
        };
      }

      return null;
    }
  } catch (e) {
    console.warn("Cannot find the location in the event");
    console.warn(e);
    return null;
  }
  return null;

}

export function noResponse(status: CalendarStatus) {
  return (
    status.response === "NoResponseReceived" ||
    status.response === "None" ||
    status.response === "notResponded" ||
    status.response === "none" ||
    status.response === "tentative" ||
    status.response === "Tentative"
  );
}

export function getAttendeeFromLocationAndAttendees(event: CalendarEvent,
                                                    resources: AssistResourceBM[]
) {
  const resourcesInAttendees = event.attendees.filter(
    (x) => x.resource === true
  );
  if (resourcesInAttendees.length === 1) {
    return resourcesInAttendees[0];
  }
  const foundStandardWay = event.attendees.find((x) => {
    try {

      return event.locations.find(
        location =>
          (x.emailAddress.address != null &&
            x.emailAddress.address.toUpperCase() ===
            location.locationUri?.toUpperCase()) ||
          (x.emailAddress.name != null &&
            x.emailAddress.name?.toUpperCase() ===
            location.displayName?.toUpperCase()) ||
          (location.uniqueId != null &&
            x.emailAddress.address.toUpperCase() ===
            location.uniqueId?.toUpperCase()) ||
          (x.emailAddress.name != null &&
            x.emailAddress.name.toUpperCase() ===
            location.locationUri?.toUpperCase()) ||
          (x.emailAddress.address != null &&
            x.emailAddress.address.toUpperCase() ===
            location.locationUri?.toUpperCase()) ||
          (x.emailAddress.name != null &&
            x.emailAddress.name?.toUpperCase() ===
            location.displayName?.toUpperCase()) ||
          (x.emailAddress.address != null &&
            location.displayName != null &&
            x.emailAddress.address?.toUpperCase() ===
            location.displayName?.toUpperCase())
      ) != null;
    } catch (ex) {
      console.warn("Malformed Microsoft response - attendee");
      console.warn(ex);
      console.table(JSON.stringify(event, null, 2));
      return false;
    }
  });

  // Pokud se nenajde zkusime to hledat obecne v resourcech a naparovat.
  if (foundStandardWay == null) {
    // nejdrive najdeme location
    const foundResourceOfLocation = resources.find(r => {
      return event.locations.find(location =>
        r.username.toUpperCase() === location.locationUri?.toUpperCase() ||
        r.username.toUpperCase() === location.displayName?.toUpperCase() ||
        r.username.toUpperCase() === location.uniqueId?.toUpperCase() ||
        r.email.toUpperCase() === location.locationUri?.toUpperCase() ||
        r.email.toUpperCase() === location.displayName?.toUpperCase() ||
        r.email.toUpperCase() === location.uniqueId?.toUpperCase() ||
        r.displayName.toUpperCase() === location.locationUri?.toUpperCase() ||
        r.displayName.toUpperCase() === location.displayName?.toUpperCase() ||
        r.displayName.toUpperCase() === location.uniqueId?.toUpperCase()
      ) != null;


    });

    if (foundResourceOfLocation) {
      // pokud jsme nalezli resource z lokace, muzeme zkusit najit attendeeho.
      const foundAttendeeFromFoundResource = event.attendees.find((x) => {
        try {
          return (x.emailAddress?.address.toUpperCase() == foundResourceOfLocation.email.toUpperCase() ||
            x.emailAddress?.address.toUpperCase() == foundResourceOfLocation.username.toUpperCase() ||
            x.emailAddress?.address.toUpperCase() == foundResourceOfLocation.displayName.toUpperCase() ||

            x.emailAddress?.name.toUpperCase() == foundResourceOfLocation.email.toUpperCase() ||
            x.emailAddress?.name.toUpperCase() == foundResourceOfLocation.username.toUpperCase() ||
            x.emailAddress?.name.toUpperCase() == foundResourceOfLocation.displayName.toUpperCase()
          );
        } catch (ex) {
          console.warn("Malformed Microsoft response - attendee");
          console.warn(ex);
          console.table(JSON.stringify(event, null, 2));
          return false;
        }
      });

      return foundAttendeeFromFoundResource;
    } else {
      // Pokud nenajdeme ani tak, zkusime uz absolutni hadani - nalezt ciste na zaklade attendes
      const foundFromOnlyAttendees = event.attendees.find(attendee => {
        return resources.find(resource =>
          attendee.emailAddress?.address?.toUpperCase() === resource.email?.toUpperCase() ||
          attendee.emailAddress?.address?.toUpperCase() === resource.username?.toUpperCase() ||
          attendee.emailAddress?.address?.toUpperCase() === resource.displayName?.toUpperCase() ||
          attendee.emailAddress?.name?.toUpperCase() === resource.email?.toUpperCase() ||
          attendee.emailAddress?.name?.toUpperCase() === resource.username?.toUpperCase() ||
          attendee.emailAddress?.name?.toUpperCase() === resource.displayName?.toUpperCase()
        ) != null;
      });

      if (foundFromOnlyAttendees) {
        console.warn("Event-resource pairing was semi-guessed on attandees");
        console.warn(event);
        return foundFromOnlyAttendees;
      } else {
        return null;
      }
    }
  }
  return foundStandardWay;
}


const randomColors = [
  "red",
  "blue",
  "green",
  "orange",
  "lightblue",
  "yellow",
  "gray",
  "lightgray",
  "cyan"
];

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function getAttendees(foundEventToConfirm: IdentifiedAssistEvent) {
  return foundEventToConfirm.attendees
    .filter((x) => x.type === "optional")
    .map((a) => ({
      name: a.emailAddress.name ?? a.emailAddress.address,
      email: a.emailAddress.address,
      status: a.status.response,
      initials:
        a.emailAddress.name != null
          ? a.emailAddress.name.trim()
            .split(" ").length > 1
            ? a.emailAddress.name.trim()
              .split(" ")[0][0] +
            a.emailAddress.name.trim()
              .split(" ")[1][0]
            : "@"
          : "@",
      color:
        randomColors[getRandomInt(randomColors.length - 1)]
    }));
}


export function resourcesFullfilRequirement(
  resource: AssistResourceBM,
  requirement: Requirement,
  filter: FilterBase,
  licensingService: LicensingService,
  configService: ConfigService
): boolean {


  const anyAttendee = filter.attendees.length > 0;
  const filterByAttendeeFilter =  !anyAttendee  || licensingService.isPremiumResourceLike(resource);

  const attendeeCapacity = !anyAttendee || resource.capacity >= filter.attendees.filter(x=> !x.remotely).length;

  const filtersFromConfig = configService.value.filters.filter(
    (x) => x.resourceTypeId === requirement.typeId && x.filterable === true
  );

  const filterFromResourceVisibilityRestrictionDescription = configService.value.resourceVisibilityRestriction == 1
    || (resource.conditions.reservedTo == null || resource.conditions.reservedTo == "you")
  // Typ resourcu musi odpovidat
  const isTheSameType = resource.resourceTypeId === requirement.typeId;

  // Filtrovani podle kapacity
  const capacityRequire =
    requirement.capacity != null
      ? resource.capacity >= requirement.capacity[0] &&
      resource.capacity <= requirement.capacity[1]
      : true;

  // Filtrovani podle areas
  const areaRequire =
    requirement.areas && requirement.areas.length > 0
      ? resource.mapInfos.find(
      (workplaceMapinfo) =>
        requirement.areas!.find(
          (requirementArea) => requirementArea === workplaceMapinfo.areaId
        ) != null
    ) != null
      : true;

  // Filtrovani podle obecnych filtru

  const bools = (requirement.filters as any[]).map((f, filterIndex) => {
    if (Array.isArray(f) ? f.length > 0 : f != null) {
      const filterDefinition = filtersFromConfig[filterIndex];
      const dataTypeId = filterDefinition.dataTypeId;
      const properties = resource.properties.filter(
        (x) => x.propertyId == filterDefinition.id
      );
      const value: any = null;
      switch (dataTypeId) {
        case 1: {
          return properties.length > 0
            ? properties[0].valueBoolean === f
            : true;
        }
        case 2: {
          return properties.length > 0
            ? properties[0].valueNumber >= f[0] &&
            properties[0].valueNumber <= f[1]
            : true;
        }
        case 3: {
          return properties.length > 0
            ? properties[0].valueDecimal >= f[0] &&
            properties[0].valueDecimal <= f[1]
            : true;
        }
        case 4: {
          return properties.length > 0
            ? (remove(properties[0].valueText) ?? '')
              .toUpperCase()
              .includes(remove(f).toUpperCase())
            : false;
        }
        case 5: {
          const valueChoiceIds =
            properties.length > 0 ? properties[0].valueChoiceIds : [];
          const intersect = valueChoiceIds.filter(
            (x) => f.find((e) => e.id === x) != null
          );
          return filterDefinition.matchAll === true
            ? intersect.length === f.length
            : intersect.length > 0;
        }
        default:
          return false;
      }
    } else {
      return true;
    }
  });

  const anyFalse = bools.find((x) => x === false) != null;
  return (
    filterFromResourceVisibilityRestrictionDescription &&
    isTheSameType &&
    capacityRequire &&
    areaRequire &&
    attendeeCapacity &&
    filterByAttendeeFilter &&
    !anyFalse
  );
}
