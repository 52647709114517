import { EnvironmentProviders } from "@angular/core";
import { combineReducers, provideState } from "@ngrx/store";
import { sharedReducers } from "./reducers";
import { provideEffects } from "@ngrx/effects";
import { ReservationsEffects } from "./effects/reservations.effects";
import {
  AreasEffects,
  ColleagueEffects,
  ColleagueEventsEffects, CurrentUserEffects,
  EventsEffects, FavouritesEffects,
  RoomsEffects,
  SchedulesEffects,
  UserEffects
} from "./effects";

export const DataProviders: EnvironmentProviders[] = [
  provideState({
    name: 'shared',
    reducer: combineReducers(sharedReducers)
  }),
  provideEffects([
    ReservationsEffects,
    UserEffects,
    EventsEffects,
    RoomsEffects,
    AreasEffects,
    SchedulesEffects,
    ColleagueEffects,
    ColleagueEventsEffects,
    FavouritesEffects,
    CurrentUserEffects,
  ])
];
