import { Filter, FloorplanFilter } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import {add, addDays, set, startOfDay} from 'date-fns';
import * as FilterActions from '../actions/filter.actions';

import { getNextQuarter } from '../utils/calculations.functions';

const newNow = getNextQuarter(new Date());

export const filterInitialState: Filter = {
  duration: null,
  date: new Date(),
  requirements: [],
  availability: true,
  attendees: []
};

export const floorplanFilterInitialState: FloorplanFilter = {
  time: newNow,
  duration: null,
  date: startOfDay(newNow),
  areaId: null,
  requirements: [],
  availability: true,
  fromColleague: false,
  attendees: []
};

const floorplan_filter_reducer = createReducer(
  floorplanFilterInitialState,
  on(FilterActions.setFloorplanFilter, (state, { filter }) => ({ ...state, ...filter })),
  on(FilterActions.setFloorplanFilterTime, (state, { args: { time } }) => ({
    ...state,
    time: time,
  })),
  on(FilterActions.setFloorplanFilterDateAndTime, (state, { args: { time, date } }) => ({
    ...state,
    time: time,
    date: date
  })),
  on(
    FilterActions.setFloorplanFilterDateAndDuration,
    (state, { args: { fromColleague, date, duration, requirements, time } }) => ({
      ...state,
      date: date,
      duration: duration,
      fromColleague:fromColleague,
      time:
        time == null
          ? date.getDay() != state.date.getDay()
            ? date
            : state.time
          : time,
      requirements: requirements ?? [],
    })
  ),
  on(FilterActions.removeCollegueFilter, (state) => ({...state, fromColleague: false})),
  on(
    FilterActions.setFloorplanFilterWithDuration,
    (state, { args: { duration, requirements } }) => ({
      ...state,
      duration: duration,
      requirements: requirements ?? [],
    })
  ),
  on(
    FilterActions.setFloorplanFilterDateAndDurationWithoutRefresh,
    (state, { args: { duration } }) => ({ ...state, duration: duration })
  ),
  on(FilterActions.setFloorplanFilterAreaId, (state, { args: { areaId } }) => ({
    ...state,
    areaId: areaId,
  })),
  on(FilterActions.setFloorplanAvailability, (state, { availability }) => ({
    ...state,
    availability: availability,
  }))
);

const filter_reducer = createReducer(
  filterInitialState,
  on(FilterActions.setFilter, (state, { filter }) => ({ ...state, ...filter })),
  on(FilterActions.setAvailability, (state, { availability }) => ({
    ...state,
    availability: availability,
  })),
  on(FilterActions.setFilterDefaults, (state, action) => ({
    ...state,
    duration: null,
    date: new Date(),
    e: set(
      add(
        set(new Date(), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }),
        { hours: 24, minutes: 0 }
      ),
      { seconds: 0 }
    ),
  }))
);

export function filterReducer(state: Filter, action: Action) {
  return filter_reducer(state, action);
}

export function floorplanFilterReducer(state: FloorplanFilter, action: Action) {
  return floorplan_filter_reducer(state, action);
}
