import { createSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';


export const events = createSelector(selectShared, (state) => state.events);

export const filteredEvents = createSelector(events, (packedEvents) => {
  if (packedEvents.loading) {
    return packedEvents;
  }

  const multiEvents: {
    [seriesMasterId: string]: any;
  } = {};

  const addToMultiEvents = (id, event) => {
    if (multiEvents[id]) return;
    if (
      new Date(event.start.dateTime) >= new Date() ||
      new Date(event.end.dateTime) >= new Date()
    ) {
      multiEvents[id] = event;
    }
  };
  packedEvents.data.forEach((event) => {
    if (event.type === 'occurrence') {
      addToMultiEvents(event.seriesMasterId, event);
    }
  });
  const uniqueFiltered = packedEvents.data.filter(
    (event) =>
      (new Date(event.start.dateTime) >= new Date() ||
        new Date(event.end.dateTime) >= new Date()) &&
      (event.type !== 'occurrence' ||
        (event.type === 'occurrence' &&
          multiEvents[event.seriesMasterId].start.dateTime ===
            event.start.dateTime))
  );
  return {
    loading: packedEvents.loading,
    data: packedEvents.data != null ? uniqueFiltered : null,
  };
});
