import { ActionReducerMap } from '@ngrx/store';
import { userReducer } from './user.reducer';
import { eventsReducer } from './events.reducer';
import { SharedState } from '../models';
import { resourcesReducer } from './resources.reducer';

import { filterReducer, floorplanFilterReducer } from './filter.reducer';
import {
  floorplanSchedulesReducer,
  quickSchedulesReducer,
  schedulesReducer,
} from './schedules.reducer';
import { timelineReducer } from './timeline.reducer';
import { operationsReducer } from './operations.reducer';
import { selectionsReducer } from './selections.reducer';
import { portalReducer } from './portal.reducer';
import { areasReducer } from './areas.reducer';
import { colleagueReducer } from './colleague.reducer';
import { colleagueeventsReducer } from './colleague-events.reducer';
import { favouritesReducer } from './favourites.reducer';
import { reservationsReducer } from './reservations.reducer';
import { currentUserReducer } from './current-user.reducer';
import {userSettingsReducer} from "./user-settings.reducer";
import {filterAttendeesReducer} from "./filter-attendees.reducer";

export const sharedReducers: ActionReducerMap<SharedState> = {
  user: userReducer,
  events: eventsReducer,
  colleagueEvents: colleagueeventsReducer,
  resources: resourcesReducer,
  filter: filterReducer,
  floorplanFilter: floorplanFilterReducer,
  schedules: schedulesReducer,
  quickSchedules: quickSchedulesReducer,
  floorPlanSchedules: floorplanSchedulesReducer,
  timelineSettings: timelineReducer,
  operations: operationsReducer,
  selections: selectionsReducer,
  portal: portalReducer,
  areas: areasReducer,
  colleague: colleagueReducer,
  favourites: favouritesReducer,
  reservations: reservationsReducer,
  currentUser: currentUserReducer,
  userSettings: userSettingsReducer,
  filterAttendees: filterAttendeesReducer
};
