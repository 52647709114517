import { CalendarServerToken, LoginVM, ServerToken, ServerTokenResponse } from "../models/authorization";
import {LoginRequestBM} from "../models/authorization";
import {CueResponse, DataSourceResult} from "../models/common";
import {EventActionRequestBM, EventStatusResponse} from "../models/events";
import {
  WebexCreateMeeting, WebexCreateMeetingResponse,
  WebexPair,
  WebexPairResponse,
  WebexUserSettingsModel,
  WebexUserSettingsModelResponse
} from "../models/webex";
import {AssistResourceBM} from "../models/resources";
import {NewsItemBM} from "../models/news";
import {QrCodeInfoResponseBM} from "../models/navigation";




export type HttpMethodType = 'post' | 'get' | 'put' | 'delete';

export interface ApiRequest<TPayload = void, TResponse = any> {
  url: string;
  payload?:TPayload;
  method: HttpMethodType;
  mapFn?: (response: any) => TResponse,
  catchFn?: (error: any) => TResponse
}
// ------------------
// news
// ---------------------
export function privateNews(query: string): ApiRequest<object, DataSourceResult<NewsItemBM>> {
  return {
    url: '/api/news/public?includeBody=true&showPrivate=true&&' + query,
    method: "post",
    payload: {}
  };
}

export function titleNews(timestamp: string): ApiRequest<object, DataSourceResult<NewsItemBM | boolean>> {
  let url =  '/api/news/title';
  if (timestamp != null) {
    url += '?cachedTimestamp='+timestamp;
  }
  return {
    url: url,
    method: "get"
  };
}



export function publicNews(query: string): ApiRequest<object, DataSourceResult<NewsItemBM>> {
  return {
    url: '/api/news/public?' + query,
    method: "post",
    payload: {}
  };
}


// -----------------------
// QR codes
// -----------------------
export function qrCodeInfo(id: string, fn: any): ApiRequest<string, CueResponse<QrCodeInfoResponseBM>> {
  return {
    url: '/api/qrcode/info/' + id,
    method: "get",
    catchFn: fn
  };
}


// -----------------------
// cache
// -----------------------
export function cacheGet(fromDate: Date, toDate: Date, uniqueMails: any[]) : ApiRequest<{start?: Date, end?: Date, emails: any[]}, any> {
  return {
    url: '/api/cache/get',
    method: "post",
    payload: {
      start: fromDate,
      end: toDate,
      emails: uniqueMails
    }
  }
}



// -------------------------
//  STATUSES
// -------------------------
export function eventStatuses(requests: { email: string, iCalUid: string, id?: string }[]) : ApiRequest<{ email: string, iCalUid: string, id?: string }[], EventStatusResponse[]> {
  return {
    url: '/api/event/statuses',
    method: "post",
    payload: requests
  }
}


// ----------------------------------
//   AUTH
// ----------------------------------

export function oAuthLogout() : ApiRequest {
  return {
    url: "/api/account/oauth-logout",
    method: "get"
  }
}


export function refresh(refreshToken: string, token?: string) : ApiRequest<{ token: string, refreshToken: string }, CueResponse<{token: string, refreshToken: string}>> {
  return {
    url: "/api/account/refresh",
    method: "post",
    payload: {
      refreshToken: refreshToken,
      token: token

    }
  }
}


export function logoutEWS() : ApiRequest<void, CueResponse<void>> {
  return {
    url: "/api/account/ews-logout",
    method: "post"
  }
}

export function loginEWS(login: LoginVM) : ApiRequest<LoginRequestBM, CueResponse<{ token: string, refreshToken: string}>> {
  return {
    url: "/api/account/ews-login",
    payload:  { data: login } as LoginRequestBM,
    method: "post"
  }
}



export function isLoggedIn() : ApiRequest<void, boolean> {
  return {
    url: '/api/assist/amiguest',
    method: "get",
    mapFn: () => true,
    catchFn: ()=> false
  }
}


export function getUserInfo() : ApiRequest<void, any> {
  return {
    url: '/api/account/me',
    method: "get",
    mapFn: response => ({
      error: null,
      data: response,
      loading: false
    }),
    catchFn: (e)=> ({
      loading: false,
      error: e,
      data: null
    })
  }
}


export function oAuthToken(): ApiRequest<void,CueResponse<string>> {
  return {
    url: '/api/account/oauth-token',
    method: "get"
  }
}


export function oAuthRefreshToken(scope: string): ApiRequest<{ scope: string },CueResponse<string>> {
  return {
    url: '/api/account/refresh-oauth-token',
    method: "post",
    payload: {
      scope
    }
  }
}



export function calendarOAuthLogin(code: string, redirectUrl: string, scope: string, connectionAgentId): ApiRequest<any,CalendarServerToken> {
  return {
    url: '/api/account/calendar-oauth-login',
    method: "post",
    payload: {
      authorizationCode: code,
      redirectUrl:  redirectUrl,
      scope: scope,
      connectionAgentId: connectionAgentId
    }
  }
}


export function oAuthLogin(code: string, redirectUrl: string, scope: string): ApiRequest<any,ServerTokenResponse> {
  return {
    url: '/api/account/oauth-login',
    method: "post",
    payload: {
      authorizationCode: code,
      redirectUrl:  redirectUrl,
      scope: scope
    },
    catchFn: ()=> ({
      success: false
    })
  }
}


// ---------------------------------
// WEBEX
// ----------------------------------
export function isWebexConnected() : ApiRequest<void, CueResponse<boolean>> {
  return {
    url: '/api/integrations/webex/user-settings',
    method: "get",
    mapFn: (response: WebexUserSettingsModelResponse) => ({...response, data: response.data.paired})
  }
}

export function profileSettings() : ApiRequest<void, WebexUserSettingsModelResponse> {
  return {
    url: '/api/integrations/webex/user-settings',
    method: "get"
  }
}

export function setUserProfileSettings(settings: Partial<WebexUserSettingsModel>) : ApiRequest<Partial<WebexUserSettingsModel>> {
  return {
    url: '/api/integrations/webex/user-settings',
    method: "post",
    payload: settings
  }
}


export function pairWebex(settings: WebexPair) : ApiRequest<WebexPair, any> {
  return {
    url: '/api/integrations/webex/pair',
    method: "post",
    payload: settings
  }
}


export function unpairWebex() : ApiRequest<void, WebexPairResponse> {
  return {
    url: '/api/integrations/webex/unpair',
    method: "post"
  }
}


export function createWebexMeeting(meeting: WebexCreateMeeting) : ApiRequest<WebexCreateMeeting, CueResponse<WebexCreateMeetingResponse>> {
  return {
    url: '/api/integrations/webex/createMeeting',
    payload: meeting,
    method: "post"
  }
}


// -------------
// resources
// ---------------


export function resources(requestUrl) : ApiRequest<void, AssistResourceBM[]> {
  return {
    url: requestUrl,
    method: "get"
  }
}

export function removeFavouriteById(resourceGUID: string) : ApiRequest {
  return {
    url: "/api/users/me/favourites/" + resourceGUID,
    method: "delete"
  }
}


export function addFavouriteById(resourceGUID: string) : ApiRequest {
  return {
    url: "/api/users/me/favourites/" + resourceGUID,
    method: "post"
  }
}

export function favourireIds() : ApiRequest<void, string[]> {
  return {
    url: "/api/users/me/favourites",
    method: "get",
    mapFn: response => response.map(a=> a.resourceId),
    catchFn: () => []
  }
}

export function confirmEvent(args: EventActionRequestBM) : ApiRequest<EventActionRequestBM, CueResponse> {
  return {
    url: "/api/event/action",
    payload:  args,
    method: "post"
  }
}

export function eventCreated(args: EventActionRequestBM) : ApiRequest<EventActionRequestBM, CueResponse> {
  return {
    url: "/api/event/action",
    payload:  args,
    method: "post"
  }
}

export function eventModified(args: EventActionRequestBM) : ApiRequest<EventActionRequestBM, CueResponse> {
  return {
    url: "/api/event/action",
    payload:  args,
    method: "post"
  }
}

export function eventDeleted(args: EventActionRequestBM) : ApiRequest<EventActionRequestBM, CueResponse> {
  return {
    url: "/api/event/action",
    payload:  args,
    method: "post"
  }
}




