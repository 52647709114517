import { ErrorHandler, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import {
  map,
  mergeMap,
  switchMap,
  tap,
  delay,
  retryWhen,
  mapTo, catchError
} from "rxjs/operators";

import * as EventsActions from "../actions/events.actions";
import * as SchedulesActions from "../actions/schedules.actions";
import * as UserActions from "../actions/user.actions";
import * as RoomsActions from "../actions/resources.actions";
import { forkJoin, of, timer } from "rxjs";
import {
  betterLatestFrom,
  getAttendeeFromLocationAndAttendees,
  noResponse
} from "../utils";
import { Store } from "@ngrx/store";
import { refreshReservations, setOperation } from "../actions";
import { Router } from "@angular/router";
import { allResources, getRequirements, selectUser } from "../selectors";
import { loadFavourites } from "../actions/schedules.actions";
import { loadEventsPatched } from "../actions/events.actions";

import { add, isWithinInterval, set } from "date-fns";
import {
  ApiService,
  createWebexMeeting,
  eventCreated,
  eventDeleted,
  WebexCreateMeeting,
  WebexInvitee
} from "@cue/api";
import {
  CalendarsService,
  CalendarsServiceFactoryService
} from "@cue/calendars";
import { DataService } from "../services/data.service";
import { AssistErrorHandler, AuthService, ConfigService } from "../services";
import { AppState, busyOperation, successOperation } from "../models";

@Injectable()
export class EventsEffects {
  loadEventsWhenUserHasChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userLoaded, RoomsActions.resourcesLoaded),
      map((_) =>
        EventsActions.loadEventsPatched({
          startFrom: set(new Date(), {
            hours: 0,
            minutes: 0
          }),
          endFrom: add(
            set(new Date(), {
              hours: 0,
              minutes: 0
            }),
            { days: 30 }
          )
        })
      )
    )
  );

  loadEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.loadEventsPatched),
      betterLatestFrom((a) => this.store.select(allResources)),
      switchMap(([action, _allResources]) =>
        this.dataService.getMyEventsWhereIsResource(
          action.startFrom,
          action.endFrom,
          _allResources.data
        )
      ),
      mergeMap((events) => {
        const mode = this.authService.getMode();
        return [
          SchedulesActions.refreshSchedules(),
          EventsActions.eventsLoaded({ events })
        ];
      })
    )
  );

  finishEventById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.finishEventById),
      betterLatestFrom((a) => this.store.select(selectUser)),
      switchMap(([action, user]) =>
        this.calendarImplementation$.pipe(
          switchMap(
            implementation => implementation.finishEvent(action.eventToFinishId)
          ),
          mergeMap((resp) => [
            refreshReservations(),
            loadEventsPatched({
              startFrom: set(new Date(), {
                hours: 0,
                minutes: 0
              }),
              endFrom: add(
                set(new Date(), {
                  hours: 0,
                  minutes: 0
                }),
                { days: 30 }
              )
            })
          ])
        )
          .pipe(delay(this.authService.getMode() === "touchone-calendar" ? 0 : 3000))
      )
    )
  );

  cancelParticipationEventById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.cancelParticipationEventById),
      switchMap((action) =>
        this.calendarImplementation$.pipe(
          switchMap(implementation => implementation.deleteEvent(action.eventToCancelParticipationId))
        )
      ),
      mergeMap((success) =>
        success
          ? [
            refreshReservations(),
            loadFavourites(),
            EventsActions.loadEventsPatched({
              startFrom: set(new Date(), {
                hours: 0,
                minutes: 0
              }),
              endFrom: add(
                set(new Date(), {
                  hours: 0,
                  minutes: 0
                }),
                { days: 30 }
              )
            }),
            SchedulesActions.refreshSchedules()
          ]
          : []
      )
    )
  );

  deleteEventById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.deleteEventById),
      betterLatestFrom((a) => this.store.select(selectUser)),
      switchMap(([action, user]) =>
        this.apiService
          .call(
            eventDeleted({
              data: {
                id: action.eventToDeleteId,
                resourceUsername: action.roomEmail,
                iCalUId: action.icalId,
                resourceDisplayName: action.roomName,
                subject: action.subject,
                showAs: action.status,
                realEnd: action.end,
                realStart: action.start,
                action: "delete",
                doneBy: "assist",
                organiserName: user.data.givenName + " " + user.data.surname,
                organiserEmail: this.authService.getEmail()
              },
              orders: []
            })
          )
          .pipe(mapTo(action))
      ),
      switchMap((action) =>
        forkJoin([
          this.calendarImplementation$.pipe(
            switchMap(implementation => implementation.deleteEvent(action.eventToDeleteId))
          ),
          of(action.eventToDeleteId)
        ])
      ),
      mergeMap(([success, eid]) =>
        success
          ? [
            refreshReservations(),
            loadFavourites(),
            EventsActions.loadEventsPatched({
              startFrom: set(new Date(), {
                hours: 0,
                minutes: 0
              }),
              endFrom: add(
                set(new Date(), {
                  hours: 0,
                  minutes: 0
                }),
                { days: 30 }
              )
            }),
            SchedulesActions.refreshSchedules()
          ]
          : [EventsActions.eventDeleteFailed({ eventToDeleteId: eid })]
      )
    )
  );

  refreshReservationsOnEventPatchedAndResponded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.eventPatched, EventsActions.eventResponded),
      map(refreshReservations)
    )
  );

  tentativePatchedChecking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.eventPatched),
      betterLatestFrom((a) => this.store.select(allResources)),
      mergeMap(([action, resources]) => {
        return this.dataService
          .getMyEventsWhereIsResource(action.start, action.end, resources.data)
          .pipe(
            map((events) => {
              return events.filter((x) => x.id === action.id);
            }),
            switchMap((events) => {
              if (events.length === 0) return of(EventsActions.notFoundEvent());


              const event = events[0];


              const showChangeButton =
                !event.canceled &&
                new Date(event.end.dateTime) >= new Date();
              const attendee = getAttendeeFromLocationAndAttendees(event, resources.data);
              if (noResponse(attendee.status)) {
                return timer(5000).pipe(
                  mapTo(
                    EventsActions.eventPatched({
                      start: action.start,
                      id: action.id,
                      end: action.end
                    })
                  )
                );
              }
              return of(
                EventsActions.eventResponded({
                  id: action.id,
                  attendee: attendee,
                  showChangeButton: showChangeButton
                })
              );
            })
          );
      })
    )
  );

  tentativeCreatedChecking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.eventCreated),
      betterLatestFrom((a) => this.store.select(allResources)),
      mergeMap(([action, resources]) => {
        return this.dataService
          .getMyEventsWhereIsResource(action.start, action.end, resources.data)
          .pipe(
            map((events) => {
              return events.filter((x) => x.id === action.id);
            }),
            switchMap((events) => {
              if (events.length === 0) return of(EventsActions.notFoundEvent());

              const event = events[0];


              const showChangeButton =
                !event.canceled &&
                new Date(event.end.dateTime) >= new Date();
              const attendee = getAttendeeFromLocationAndAttendees(event, resources.data);
              if (noResponse(attendee.status)) {
                return timer(5000).pipe(
                  mapTo(
                    EventsActions.eventCreated({
                      start: action.start,
                      id: action.id,
                      end: action.end
                    })
                  )
                );
              }
              return of(
                EventsActions.eventResponded({
                  id: action.id,
                  attendee: attendee,
                  showChangeButton: showChangeButton
                })
              );
            })
          );
      })
    )
  );

  createEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.createEvent),
      tap((_) =>
        this.store.dispatch(
          setOperation({
            operation: busyOperation("reservation", "todo-predelat-na-uuid")
          })
        )
      ),
      switchMap((action) => {
        if (action.webexMeeting) {
          const webexMeetingRequest: WebexCreateMeeting = {
            eventStart: action.start,
            eventEnd: action.end,
            eventTitle: action.subject,
            eventBody: action.text,
            invitees: action.attendees.map(
              (attendee) =>
                ({
                  email: attendee.address,
                  coHost: false,
                  displayName: attendee.name
                } as WebexInvitee)
            )
          };
          return this.apiService
            .call(createWebexMeeting(webexMeetingRequest))
            .pipe(
              catchError((err, caught) => of({
                data: null,
                success: false
              })),
              tap(response => {
                if (response.data == null || !response.success) {
                  this.assistErrorHandler.handleError({
                    stack: undefined,
                    message: "Cannot create webex meetings"
                  });
                }
              }),
              switchMap((response) =>
                forkJoin([
                  of(action),
                  this.calendarImplementation$.pipe(
                    switchMap(implementation => implementation.createEvent(
                      action.visibility,
                      action.status,
                      action.subject,
                      action.text,
                      action.start,
                      action.end,
                      action.roomName,
                      action.roomEmail,
                      action.attendees,
                      action.onlineMeeting,
                      response.data && response.success ? {
                        url: response.data.weblink,
                        code: response.data.meetingNumber,
                        password: response.data.password,
                        body: action.webexMeeting.body
                      } : null
                    ))
                  )
                ])
              )
            );
        } else {
          return forkJoin([
            of(action),
            this.calendarImplementation$.pipe(
              switchMap(implementation => implementation.createEvent(
                  action.visibility,
                  action.status,
                  action.subject,
                  action.text,
                  action.start,
                  action.end,
                  action.roomName,
                  action.roomEmail,
                  action.attendees,
                  action.onlineMeeting
                )
              ))
          ]);
        }
      }),
      betterLatestFrom(() => this.store.select(allResources)),
      betterLatestFrom(() => this.store.select(getRequirements)),
      betterLatestFrom(() => this.store.select(selectUser)),
      switchMap(([[[[action, response], _allResources], requirements], user]) =>
        forkJoin([
          this.dataService
            .getMyEventsWhereIsResource(
              add(set(action.start, { seconds: 0, milliseconds: 0 }), {
                minutes: -10
              }),
              add(set(action.end, { seconds: 0, milliseconds: 0 }), {
                minutes: 10
              }),
              _allResources.data
            )
            .pipe(
              map((events) => {
                if (events.find((x) => x.id === response.id) == null)
                  throw new Error("NO Events created yet");
                return response;
              }),
              retryWhen((errors) => errors.pipe(delay(2000)))
            ),
          of(requirements),
          this.apiService
            .call(
              eventCreated({
                data: {
                  doneBy: "assist",
                  id: response.id,
                  resourceUsername: action.roomEmail,
                  iCalUId: response.iCalId,
                  resourceDisplayName: action.roomName,
                  subject: action.subject,
                  showAs: action.status.value,
                  realEnd: action.end,
                  realStart: action.start,
                  organiserName:
                    user.data.givenName + " " + user.data.surname,
                  organiserEmail: this.authService.getEmail(),
                  action: "create"
                },
                orders: action.orders
              })
            )
            .pipe(delay(this.authService.getMode() === "touchone-calendar" ? 0 : 3000))
        ])
      ),
      tap(([_, requirements]) => {
        if (requirements.length < 2) {
          this.router.navigate(["/dashboard"]);
        } else {
          this.router.navigate(["/reservations", "reserve"]);
        }
      }),
      mergeMap((a) => [
        loadFavourites(),
        refreshReservations(),
        SchedulesActions.refreshSchedules(),
        EventsActions.loadEventsPatched({
          startFrom: set(new Date(), {
            hours: 0,
            minutes: 0
          }),
          endFrom: add(
            set(new Date(), {
              hours: 0,
              minutes: 0
            }),
            { days: 30 }
          )
        }),
        EventsActions.eventCreated({
          id: a[0].id,
          start: set(new Date(), {
            hours: 0,
            minutes: 0
          }),
          end: add(
            set(new Date(), {
              hours: 0,
              minutes: 0
            }),
            { days: 30 }
          )
        }),
        setOperation({
          operation: successOperation(
            "reservation",
            null,
            "todo-predelat-na-uuid"
          )
        })
      ])
    )
  );

  private calendarImplementation: CalendarsService;
  calendarImplementation$ = this.calendarServiceFactory.getImplementation(this.authService.getMode());
  private assistErrorHandler: AssistErrorHandler;

  constructor(
    erroHandler: ErrorHandler,
    private authService: AuthService,
    private calendarServiceFactory: CalendarsServiceFactoryService,
    private apiService: ApiService,
    private actions$: Actions,
    private dataService: DataService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.assistErrorHandler = erroHandler as AssistErrorHandler;
  }
}
