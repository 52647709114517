import { createSelector } from '@ngrx/store';
import {
  AppState,
  HierarchyArea,
  PackedAreas,
  PackedHierarchyAreas,
} from '../models';
import { selectShared } from './shared.selectors';
import { floorplanFilter } from './filter.selectors';

export const allAreas = () =>
  createSelector(selectShared, (state) => state.areas);



export const selectedAreaInFloorplans = ()=> createSelector(floorplanFilter, allAreas(), (s1, s2) => {
  return s1.areaId != null ? s2.data.find(a => a.id === s1.areaId) : s2.data?.find(x=> x.parentAreaId == null);
});

export const allAreasHierarchically = () =>
  createSelector<AppState, PackedAreas, PackedHierarchyAreas>(
    allAreas(),
    (allAreas) => {
      if (allAreas.loading) {
        return {
          loading: true,
          data: [],
          flatData: [],
        };
      }

      const map = listToTree(allAreas.data);
      return {
        loading: false,
        data: map,
        flatData: allAreas.data,
      };
    }
  );

const listToTree = (listBackup) => {
  const list = JSON.parse(JSON.stringify(listBackup));
  const map = {};
  let node = null;
  const roots: HierarchyArea[] = [];

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  for (let i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentAreaId != null) {
      node.parentArea = list[map[node.parentAreaId]];
      list[map[node.parentAreaId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};
