import { PackedUser } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as ColleagueActions from '../actions/colleague.actions';

export const initialState: PackedUser = {
  loading: false,
  data: null,
};

const reducer = createReducer(
  initialState,
  on(ColleagueActions.loadColleague, (state, coll) =>
    coll == null
      ? {
          ...state,
          loading: true,
        }
      : {
          data: null,
          loading: false,
        }
  ),
  on(ColleagueActions.unloadColleague, (state) => ({
    ...state,
    data: null,
  })),
  on(ColleagueActions.colleagueLoaded, (state, { colleague }) => ({
    ...state,
    loading: false,
    data: colleague,
  }))
);

export function colleagueReducer(state: PackedUser, action: Action) {
  return reducer(state, action);
}
