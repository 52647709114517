import { TranslocoService } from "@ngneat/transloco";
import { Localization } from "../models";


export function configure(
  translocoService: TranslocoService,
  localizations: Localization[][]) {
  const availableLangs: any[] = translocoService.getAvailableLangs();
  const langFromLocalStorage = localStorage.getItem("assist-lang");
  if (langFromLocalStorage !== null) {
    const found = availableLangs.find((x) => x === langFromLocalStorage);
    const translation = localizations[0].find(
      (x) => x.language == (found ? found : "en")
    );
    translation.valueGetter()
      .then((x) => {
        translocoService.setTranslation(x, translation.language, {
          merge: false
        });
        translocoService.setActiveLang(found ? found : "en");
      });
  } else {
    const found = availableLangs.find(
      (x) => x === navigator.language.slice(0, 2)
    );
    const translation = localizations[0].find(
      (x) => x.language == (found ? found : "en")
    );
    translation.valueGetter()
      .then((x) => {
        translocoService.setTranslation(x, translation.language, {
          merge: false
        });
        translocoService.setActiveLang(found ? found : "en");
      });
    translocoService.setActiveLang(found ? found : "en");
  }
}
