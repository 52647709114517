import { createAction, props } from '@ngrx/store';
import {IdentifiedAssistEvent} from "../models";

export const loadColleagueEvents = createAction(
  '[Colleague Events] Load',
  props<{ startFrom?: Date; endFrom?: Date }>()
);

export const colleagueEventsLoaded = createAction(
  '[Colleague Events] Loaded',
  props<{ events: IdentifiedAssistEvent[] }>()
);
