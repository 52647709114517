import { createAction, emptyProps, props } from "@ngrx/store";
import { AssistTimelineDefaultsBM } from '@cue/api';
import {
  Filter, FloorplanFilter,
  UpdateFloorplanFilterAreaIdArgs,
  UpdateFloorplanFilterDateAndDurationArgs, UpdateFloorplanFilterDateTimeArgs,
  UpdateFloorplanFilterTimeArgs, UpdateFloorplanFilterWithDurationArgs,
} from '../models';



export const setFloorplanFilterWithDuration = createAction(
  '[Floorplan Filter] SetFloorplan Filter With Duration',
  props<{ args: UpdateFloorplanFilterWithDurationArgs }>()
);

export const setFloorplanFilterDateAndDuration = createAction(
  '[Floorplan Filter] Set Date And Duration',
  props<{ args: UpdateFloorplanFilterDateAndDurationArgs }>()
);
export const setFloorplanFilterDateAndDurationWithoutRefresh = createAction(
  '[Floorplan Filter] Set Date And Duration Without Refresh',
  props<{ args: UpdateFloorplanFilterDateAndDurationArgs }>()
);

export const removeCollegueFilter = createAction(
  '[Floorplan Filter] Collegue filter',
  emptyProps
);

export const setFloorplanFilterDateAndTime = createAction(
  '[Floorplan Filter] Set date time',
  props<{ args: UpdateFloorplanFilterDateTimeArgs }>()
);

export const setFloorplanFilterTime = createAction(
  '[Floorplan Filter] Set time',
  props<{ args: UpdateFloorplanFilterTimeArgs }>()
);
export const setFloorplanFilterAreaId = createAction(
  '[Floorplan Filter] Set area Id',
  props<{ args: UpdateFloorplanFilterAreaIdArgs }>()
);

export const setFilter = createAction(
  '[Filter] Set',
  props<{ filter: Filter }>()
);

export const setFloorplanFilter = createAction(
  '[Floorplan Filter] Set',
  props<{ filter: FloorplanFilter }>()
);


export const setPreviousDayFilter = createAction(
  '[Filter] PreviousDate'
);


export const setNextDayFilter = createAction(
  '[Filter] NextDate'
);

export const setDayFilter = createAction(
  '[Filter] Set Date filter',
  props<{
    newDate: Date
  }>()
);



export const setFilterDefaults = createAction(
  '[Filter] Set Filter Defaults',
  props<AssistTimelineDefaultsBM>()
);

export const setFloorplanFilterDefaults = createAction(
  '[Floorplan Filter] Set Filter Defaults',
  props<{
    duration: { hours: number; minutes: number };
    start: {
      hours: number;
      minutes: number;
    };
    addEnd: {
      hours: number;
      minutes: number;
    };
  }>()
);

export const setAvailability = createAction(
  '[Filter] Set availability',
  props<{ availability: boolean }>()
);
export const setFloorplanAvailability = createAction(
  '[Floorplan Filter] Set availability',
  props<{ availability: boolean }>()
);


export const setFloorplanPreviousDayFilter = createAction(
  '[Floorplan Filter] PreviousDate'
);


export const setFloorplanNextDayFilter = createAction(
  '[Floorplan Filter] NextDate'
);

export const setFloorplanDayFilter = createAction(
  '[Floorplan Filter] Set Date filter',
  props<{
    newDate: Date
  }>()
);

