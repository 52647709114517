import { createAction, props } from '@ngrx/store';
import { AssistResourceBM } from '@cue/api';

export const loadResources = createAction('[Resources] Load');
export const onlyLoadResources = createAction('[Resources] Only Load');

export const resourcesLoaded = createAction(
  '[Resources] Loaded',
  props<{ resources: AssistResourceBM[] }>()
);

export const onlyResourcesLoaded = createAction(
  '[Resources] Loaded Only',
  props<{ resources: AssistResourceBM[] }>()
);

export const addToFavourite = createAction(
  '[Resources] Add to favourite',
  props<{ id: string }>()
);
export const favouriteAdded = createAction('[Resources] Favourite added');
export const removeFromFavourites = createAction(
  '[Resources] Remove from favourites',
  props<{ id: string }>()
);
export const favouriteRemoved = createAction('[Resources] Favourite removed');
