import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable, filter, map, forkJoin, take, tap} from 'rxjs';
import { Store } from '@ngrx/store';

import {currentUser, currentUserSettings} from "../selectors";
import {ConfigService} from "../services";
import {loadCurrentUser, loadCurrentUserSettings, setFloorplanFilterAreaId} from "../actions";
import {AppState} from "../models";


@Injectable({ providedIn: 'root' })
export class UserRequiredGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private configService: ConfigService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.store.dispatch(loadCurrentUser());
    this.store.dispatch(loadCurrentUserSettings());

    const currentUser$ = this.store.select(currentUser).pipe(
      filter((x) => !x.loading),
      take(1)
    );

    const userSettings$ = this.store.select(currentUserSettings).pipe(
      filter((x) => !x.loading),
      take(1),
      tap(settings => {
        if (settings.data.location.homeAreaId != null) {
          this.store.dispatch(setFloorplanFilterAreaId({
            args: {
              areaId: settings.data.location.homeAreaId
            }
          }))
        }
      })
    );

    return forkJoin([currentUser$, userSettings$]).pipe(
      map(([packedUser, packedSettings]) =>  {
        if (packedUser && packedUser.error == null) {
          if (
            packedUser.data!.isGuest &&
            !this.configService.value.allowGuests
          ) {
            return this.router.parseUrl('/account/guest');
          } else {
            return true;
          }
        }
        const urlTree = this.router.parseUrl('/account/login');
        urlTree.queryParams['return_url'] = this.router.url;
        return urlTree;
      })
    );
  }
}
