import { Injectable } from "@angular/core";
import {
  Observable,
  BehaviorSubject,
  forkJoin,
  of,
  from,
  map,
  mapTo,
  switchMap,
  tap,
  catchError
} from "rxjs";

import { NgForage } from "ngforage";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { setFilterDefaults } from "../actions/filter.actions";
import { ApiService, AssistResourceBM, resources } from "@cue/api";
import { ConfigService } from "./config.service";
import { AppState, Area } from "../models";
import { AuthService } from "./auth.service";
import { LicensingService } from "@cue/licensing";

interface ResourcesCache {
  data: any;
  timestamp: string;
  username: string;
  version: number;
}

const RESOURCES_CACHE_KEY = "ResourcesCache";

@Injectable({ providedIn: "root" })
export class DbService {
  private static CURRENT_VERSION = 5;

  initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resources: AssistResourceBM[] = [];
  areas: Area[] = [];


  getResources() {
    let url = "/api/assist/resources";

    const request = (requestUrl: string) =>
      this.apiService.callObserveResponse(resources(requestUrl)).pipe(
        switchMap((response, index) => {
          if (response.status === 204) {
            return from(
              this.ngf.getItem<ResourcesCache>(RESOURCES_CACHE_KEY)
            ).pipe(map((x) => x?.data));
          }
          if (response.status === 200) {
            let cacheHeader = undefined;
            try {
              cacheHeader = response.headers.get("CachedResourcesTimeStamp");
            } catch {
              cacheHeader = null;
            }
            if (cacheHeader) {
              return from(
                this.ngf.setItem(RESOURCES_CACHE_KEY, {
                  timestamp: cacheHeader,
                  username: this.authService.getEmail(),
                  data: response.body,
                  version: DbService.CURRENT_VERSION
                } as ResourcesCache)
              ).pipe(mapTo(response.body as any[]));
            } else {
              return from(this.ngf.removeItem(RESOURCES_CACHE_KEY)).pipe(
                mapTo(response.body as any[])
              );
            }
          }
          console.error("Error loading resources - check network tab");
          return of([]);
        }),
        map((x) => {
          return x.map((a: any) => ({
            ...a,
            timezone: a.timezone == null || a.timezone === ""
              ? "UTC"
              : a.timezone === "Custom time zone" ? "UTC" : a.timezone
          }));
        })
      );
    return from(this.ngf.getItem<ResourcesCache>(RESOURCES_CACHE_KEY)).pipe(
      switchMap((cache) => {
        if (cache !== null && cache.version >= DbService.CURRENT_VERSION) {
          // je nacachovano
          if (cache.username !== this.authService.getEmail()) {
            return from(this.ngf.removeItem(RESOURCES_CACHE_KEY)).pipe(
              switchMap((_) => request(url))
            );
          } else {
            url =
              url + "?cachedTimestamp=" + encodeURIComponent(cache.timestamp);
          }
        } else {
          // neni nacachovano - nedela se nic
        }
        return request(url);
      })
    );
  }

  constructor(
    private actions: Actions,
    private readonly ngf: NgForage,
    private apiService: ApiService,
    private configService: ConfigService,
    private authService: AuthService,
    private store: Store<AppState>,
    private licensingService: LicensingService
  ) {
  }

  initialize(): Observable<any> {
    if (this.configService.value.timelineDefaults) {
      this.store.dispatch(
        setFilterDefaults(this.configService.value.timelineDefaults)
      );
    }

    return forkJoin([this.getResources()]).pipe(
      map(([resources]) => {
        return resources.filter((r: AssistResourceBM) => r.mapInfos.length > 0);
      }),
      tap((resources: AssistResourceBM[]) => {
        this.areas = this.configService.value.areas.map((x) => ({
          ...x,
          maxUt: x.maxUt != null ? x.maxUt : 100
        }));
        this.resources = resources.map(
          (r) => ({
            ...r,
            reservedTo: r.conditions.reservedTo
          })
        ).filter(r => this.licensingService.availableForServerType(r, this.configService.value.serverType));
      }),
      tap(() => {
        this.initialized$.next(true);
        this.initialized$.complete();
      }),
      catchError(e => {
        return from(this.ngf.removeItem(RESOURCES_CACHE_KEY)).pipe(
          tap(_ => {
            window.alert("Error loading resources:" + e.stack);
            console.error(e.stack);
          }),
          tap(_ => window.alert("Click to reload browser")),
          tap(_ => location.reload())
        );
      })
    );
  }
}
