import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap } from 'rxjs/operators';
import * as ColleagueEventsActions from '../actions/colleague-events.actions';
import * as ColleagueActions from '../actions/colleague.actions';
import { Store } from '@ngrx/store';
import { add, set } from 'date-fns';
import { CalendarsServiceFactoryService } from '@cue/calendars';
import {AuthService, ConfigService, DataService} from '../services';
import { AppState } from '../models';

@Injectable()
export class ColleagueEventsEffects {
  loadColeagueEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ColleagueActions.loadColleague),
      switchMap((action) =>

        this.dataService.getEventsForCollegue(
          action.identifier,
          set(new Date(), {
            hours: 0,
            minutes: 0,
            seconds: 0,
          }),
          add(
            set(new Date(), {
              hours:0,
              minutes:0,
              seconds: 0,
            }),
            {
              days: 5,
              minutes: 0,
            }
          )
        )
      ),
      map((events) =>
        ColleagueEventsActions.colleagueEventsLoaded({
          events: events,
        })
      )
    )
  );
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private dataService: DataService
  ) {}
}
