import { PackedSchedules, Schedule } from '../models';
import { createReducer, on, Action } from '@ngrx/store';
import * as SchedulesActions from '../actions/schedules.actions';

export const initialSchedulesState: {
  schedules: Schedule[];
  loaded: boolean;
  start: Date | null;
} = {
  schedules: [],
  loaded: false,
  start: null,
};

const favourites_reducer = createReducer(
  initialSchedulesState,
  on(SchedulesActions.loadFavourites, (state) => ({
    ...state,
  })),
  on(SchedulesActions.favouritesLoaded, (state, { schedules, start }) => ({
    ...state,
    loaded: true,
    schedules: schedules,
    start: start,
  }))
);

export function favouritesReducer(
  state: { schedules: Schedule[]; loaded: boolean; start: Date },
  action: Action
) {
  return favourites_reducer(state, action);
}
