
import {createReducer, on} from "@ngrx/store";
import {FilterAttendee, Pack} from "../models";
import {   refreshSchedules, schedulesLoaded } from '../actions/schedules.actions'
const initialState: Pack<FilterAttendee[]> = {
  loading: false,
  data: [],
  error: null
};


export const filterAttendeesReducer = createReducer(
  initialState,
  on(refreshSchedules, (state) => ({
    ...state,
    loading: true,
    data: []
  })),
  on(schedulesLoaded, (state, { attendees}) => ({
    ...state,
    loading: false,
    data: attendees
  }))
)
