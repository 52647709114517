import { createReducer, on, Action } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import { PackedUser } from '../models';

export const initialState: PackedUser = {
  loading: false,
  data: null,
};

const reducer = createReducer(
  initialState,
  on(UserActions.loadUser, (state) => ({
    ...state,
    loading: true,
  })),
  on(UserActions.userLoaded, (state, { user }) => ({
    ...state,
    loading: false,
    data: user,
  }))
);

export function userReducer(state: PackedUser, action: Action) {
  return reducer(state, action);
}
